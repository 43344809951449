@charset "UTF-8";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
/* cyrillic-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("Manrope.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "IconFont";
  src: url("IconFont.woff2?t=1734612423494") format("woff2"), url("IconFont.woff?t=1734612423494") format("woff"), url("IconFont.ttf?t=1734612423494") format("truetype");
}
.iconfont {
  font-family: "IconFont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}

.icon-align-center:before {
  content: "\e901";
}

.icon-align-justify:before {
  content: "\e902";
}

.icon-align-left:before {
  content: "\e903";
}

.icon-align-right:before {
  content: "\e904";
}

.icon-arrow-left-fill:before {
  content: "\e905";
}

.icon-arrow-down:before {
  content: "\e906";
}

.icon-arrow-go:before {
  content: "\e907";
}

.icon-arrow-left:before {
  content: "\e908";
}

.icon-arrow-right:before {
  content: "\e909";
}

.icon-arrow-up:before {
  content: "\e90a";
}

.icon-article:before {
  content: "\e90b";
}

.icon-attachment:before {
  content: "\e90c";
}

.icon-bold:before {
  content: "\e90d";
}

.icon-building:before {
  content: "\e90e";
}

.icon-calendar-event:before {
  content: "\e90f";
}

.icon-shield-star:before {
  content: "\e910";
}

.icon-shield:before {
  content: "\e911";
}

.icon-shield-cross:before {
  content: "\e912";
}

.icon-check:before {
  content: "\e913";
}

.icon-checkbox-blank:before {
  content: "\e914";
}

.icon-checkbox:before {
  content: "\e915";
}

.icon-child:before {
  content: "\e916";
}

.icon-close:before {
  content: "\e917";
}

.icon-file-copy:before {
  content: "\e918";
}

.icon-file-list:before {
  content: "\e919";
}

.icon-download:before {
  content: "\e91a";
}

.icon-drag-move:before {
  content: "\e91b";
}

.icon-drawer-close:before {
  content: "\e91c";
}

.icon-drawer-open:before {
  content: "\e91d";
}

.icon-drone:before {
  content: "\e91e";
}

.icon-earth:before {
  content: "\e91f";
}

.icon-edit:before {
  content: "\e920";
}

.icon-error-warning-fill:before {
  content: "\e921";
}

.icon-eye:before {
  content: "\e922";
}

.icon-eye-off:before {
  content: "\e923";
}

.icon-file-edit:before {
  content: "\e924";
}

.icon-file-forbid:before {
  content: "\e925";
}

.icon-file-info:before {
  content: "\e926";
}

.icon-file-mark:before {
  content: "\e927";
}

.icon-file-unknow:before {
  content: "\e928";
}

.icon-file-user:before {
  content: "\e929";
}

.icon-file-shield:before {
  content: "\e92a";
}

.icon-filter:before {
  content: "\e92b";
}

.icon-flight-land:before {
  content: "\e92c";
}

.icon-flight-takeoff:before {
  content: "\e92d";
}

.icon-gamepad:before {
  content: "\e92e";
}

.icon-arrow-go-back:before {
  content: "\e92f";
}

.icon-arrow-go-forward:before {
  content: "\e930";
}

.icon-information-fill:before {
  content: "\e931";
}

.icon-information:before {
  content: "\e932";
}

.icon-italic:before {
  content: "\e933";
}

.icon-list-ordered:before {
  content: "\e934";
}

.icon-list-unordered:before {
  content: "\e935";
}

.icon-loader:before {
  content: "\e936";
}

.icon-mail:before {
  content: "\e937";
}

.icon-treasure-map:before {
  content: "\e938";
}

.icon-map-pin:before {
  content: "\e939";
}

.icon-menu-fold:before {
  content: "\e93a";
}

.icon-menu-unfold:before {
  content: "\e93b";
}

.icon-more:before {
  content: "\e93c";
}

.icon-sticky-note:before {
  content: "\e93d";
}

.icon-parent:before {
  content: "\e93e";
}

.icon-pause-circle:before {
  content: "\e93f";
}

.icon-ball-pen:before {
  content: "\e940";
}

.icon-phone:before {
  content: "\e941";
}

.icon-play-fill:before {
  content: "\e942";
}

.icon-profile:before {
  content: "\e943";
}

.icon-question:before {
  content: "\e944";
}

.icon-radio-button:before {
  content: "\e945";
}

.icon-radio-button-blank:before {
  content: "\e946";
}

.icon-circle:before {
  content: "\e947";
}

.icon-refresh:before {
  content: "\e948";
}

.icon-close-circle-fill:before {
  content: "\e949";
}

.icon-reply:before {
  content: "\e94a";
}

.icon-road-map:before {
  content: "\e94b";
}

.icon-route:before {
  content: "\e94c";
}

.icon-save:before {
  content: "\e94d";
}

.icon-scissors:before {
  content: "\e94e";
}

.icon-search:before {
  content: "\e94f";
}

.icon-settings:before {
  content: "\e950";
}

.icon-shape-cylinder:before {
  content: "\e951";
}

.icon-radius:before {
  content: "\e952";
}

.icon-line-down:before {
  content: "\e953";
}

.icon-arrow-down-3:before {
  content: "\e954";
}

.icon-arrow-up-3:before {
  content: "\e955";
}

.icon-line-up:before {
  content: "\e956";
}

.icon-shape-line:before {
  content: "\e957";
}

.icon-shape-prism:before {
  content: "\e958";
}

.icon-focus:before {
  content: "\e959";
}

.icon-checkbox-circle:before {
  content: "\e95a";
}

.icon-markup:before {
  content: "\e95b";
}

.icon-rest-time:before {
  content: "\e95c";
}

.icon-error-warning:before {
  content: "\e95d";
}

.icon-close-circle:before {
  content: "\e95e";
}

.icon-strikethrough:before {
  content: "\e95f";
}

.icon-subtract:before {
  content: "\e960";
}

.icon-focus-2:before {
  content: "\e961";
}

.icon-task:before {
  content: "\e962";
}

.icon-time:before {
  content: "\e963";
}

.icon-delete-bin:before {
  content: "\e964";
}

.icon-underline:before {
  content: "\e965";
}

.icon-user-follow:before {
  content: "\e966";
}

.icon-user:before {
  content: "\e967";
}

.icon-add-circle:before {
  content: "\e968";
}

.icon-camera:before {
  content: "\e969";
}

.icon-group:before {
  content: "\e96a";
}

.icon-community:before {
  content: "\e96b";
}

.icon-tracker:before {
  content: "\e96c";
}

.icon-menu:before {
  content: "\e96d";
}

.icon-notification:before {
  content: "\e96e";
}

.icon-mail-lock:before {
  content: "\e96f";
}

.icon-sign-prohibition:before {
  content: "\e970";
}

.icon-mail-unread:before {
  content: "\e971";
}

.icon-mail-open:before {
  content: "\e972";
}

.icon-mail-close:before {
  content: "\e973";
}

.icon-lock:before {
  content: "\e974";
}

.icon-user-settings:before {
  content: "\e975";
}

.icon-play-circle:before {
  content: "\e976";
}

.icon-file:before {
  content: "\e977";
}

.icon-file-paper:before {
  content: "\e978";
}

.icon-user-add:before {
  content: "\e979";
}

.icon-checkbox-indeterminate:before {
  content: "\e97a";
}

.icon-logout-circle:before {
  content: "\e97b";
}

.icon-shield-check:before {
  content: "\e97c";
}

.icon-dashboard:before {
  content: "\e97d";
}

.icon-draft:before {
  content: "\e97e";
}

.icon-base-station:before {
  content: "\e97f";
}

.icon-home-2:before {
  content: "\e980";
}

.icon-home:before {
  content: "\e981";
}

.icon-archive:before {
  content: "\e982";
}

.icon-plane:before {
  content: "\e983";
}

.icon-checkbox-circle-fill:before {
  content: "\e984";
}

.icon-tree:before {
  content: "\e985";
}

.icon-map-pin-time:before {
  content: "\e986";
}

.icon-star:before {
  content: "\e987";
}

.icon-alarm-warning:before {
  content: "\e988";
}

.icon-forbid:before {
  content: "\e989";
}

.icon-pen-nib:before {
  content: "\e98a";
}

.icon-pin-off:before {
  content: "\e98b";
}

.icon-file-text:before {
  content: "\e98c";
}

.icon-lock-unlock:before {
  content: "\e98d";
}

.icon-arrow-left-right:before {
  content: "\e98e";
}

.icon-pen-nib-land:before {
  content: "\e98f";
}

.icon-pen-nib-takeoff:before {
  content: "\e990";
}

.icon-pen-nib-oval:before {
  content: "\e991";
}

.icon-pen-nib-prism:before {
  content: "\e992";
}

.icon-pen-nib-line:before {
  content: "\e993";
}

.icon-pin-distance:before {
  content: "\e994";
}

.icon-side-bar:before {
  content: "\e995";
}

.icon-dashboard-2:before {
  content: "\e996";
}

.icon-arrow-left-right-2:before {
  content: "\e997";
}

.icon-arrow-right-2:before {
  content: "\e998";
}

.icon-arrow-up-2:before {
  content: "\e999";
}

.icon-arrow-down-2:before {
  content: "\e99a";
}

.icon-arrow-up-down-2:before {
  content: "\e99b";
}

.icon-arrow-up-down-left:before {
  content: "\e99c";
}

.icon-dot-down:before {
  content: "\e99d";
}

.icon-dot-middle:before {
  content: "\e99e";
}

.icon-lines:before {
  content: "\e99f";
}

.icon-lines-right:before {
  content: "\e9a0";
}

.icon-hand:before {
  content: "\e9a1";
}

.icon-chat:before {
  content: "\e9a2";
}

.icon-bookmark:before {
  content: "\e9a3";
}

.icon-bookmark-fill:before {
  content: "\e9a4";
}

.icon-dot-right-small:before {
  content: "\e9a5";
}

.icon-dot-left-small:before {
  content: "\e9a6";
}

.icon-dot-middle-small:before {
  content: "\e9a7";
}

.icon-shape-cylinder-3d:before {
  content: "\e9a8";
}

.icon-segment:before {
  content: "\e9a9";
}

.icon-cylinder-top:before {
  content: "\e9aa";
}

.icon-cylinder-down:before {
  content: "\e9ab";
}

.icon-prism:before {
  content: "\e9ac";
}

.icon-prism-top:before {
  content: "\e9ad";
}

.icon-prism-down:before {
  content: "\e9ae";
}

.icon-windy:before {
  content: "\e9af";
}

.icon-temp-cold:before {
  content: "\e9b0";
}

.icon-rainy:before {
  content: "\e9b1";
}

.icon-helicopter-lpr-bg:before {
  content: "\e9b2";
}

.icon-helicopter-lpr:before {
  content: "\e9b3";
}

.icon-polygon:before {
  content: "\e9b4";
}

.icon-stamp:before {
  content: "\e9b5";
}

.icon-size-thick:before {
  content: "\e9b6";
}

.icon-size-thin:before {
  content: "\e9b7";
}

.icon-cursor:before {
  content: "\e9b8";
}

.icon-text:before {
  content: "\e9b9";
}

.icon-color-middle:before {
  content: "\e9ba";
}

.icon-color:before {
  content: "\e9bb";
}

.icon-rectangle:before {
  content: "\e9bc";
}

.icon-color-pattern:before {
  content: "\e9bd";
}

.icon-drone-swarm:before {
  content: "\e9be";
}

.icon-pencil-ruler:before {
  content: "\e9bf";
}

.icon-eject:before {
  content: "\e9c0";
}

.icon-expanded-up-down:before {
  content: "\e9c1";
}

.icon-leaving-area-path:before {
  content: "\e9c2";
}

.icon-leaving-area-track:before {
  content: "\e9c3";
}

.icon-conflict-flash:before {
  content: "\e9c4";
}

.icon-conflict-arrows:before {
  content: "\e9c5";
}

.icon-base-station-fill:before {
  content: "\e9c6";
}

.icon-sms-phone:before {
  content: "\e9c7";
}

.icon-sms-tick:before {
  content: "\e9c8";
}

.icon-mail-envelope:before {
  content: "\e9c9";
}

.icon-mail-tick:before {
  content: "\e9ca";
}

.icon-mail-error:before {
  content: "\e9cb";
}

.icon-student:before {
  content: "\e9cc";
}

.icon-school:before {
  content: "\e9cd";
}

.icon-postpone:before {
  content: "\e9ce";
}

.icon-cylinder-dash:before {
  content: "\e9cf";
}

.icon-cylinder-fill-top-down:before {
  content: "\e9d0";
}

.icon-cylinder-fill:before {
  content: "\e9d1";
}

.icon-google:before {
  content: "\e9d2";
}

.icon-alarm-warning-fill:before {
  content: "\e9d3";
}

.icon-arrow-up-double:before {
  content: "\e9d4";
}

.icon-chart:before {
  content: "\e9d5";
}

.icon-star-badge:before {
  content: "\e9d6";
}

.icon-mail-send:before {
  content: "\e9d7";
}

.icon-global:before {
  content: "\e9d8";
}

.icon-function:before {
  content: "\e9d9";
}

.icon-user-star:before {
  content: "\e9da";
}

.icon-flashlight-filled:before {
  content: "\e9db";
}

.icon-flashlight-outline:before {
  content: "\e9dc";
}

.icon-checkbox-x:before {
  content: "\e9dd";
}

.icon-map-pin-fill:before {
  content: "\e9de";
}

.icon-edit-fill:before {
  content: "\e9df";
}

.icon-map-unpin-fill:before {
  content: "\e9e0";
}

.icon-cylinder-line:before {
  content: "\e9e1";
}

.icon-external-link:before {
  content: "\e9e2";
}

.icon-moon:before {
  content: "\e9e3";
}

.icon-sun:before {
  content: "\e9e4";
}

.icon-thumb-up:before {
  content: "\e9e5";
}

.icon-thumb-down:before {
  content: "\e9e6";
}

.icon-logout-rectangle:before {
  content: "\e9e7";
}

.icon-send-plane-fill:before {
  content: "\e9e8";
}

.icon-scales-2:before {
  content: "\e9e9";
}

.icon-sun-cloudy:before {
  content: "\e9ea";
}

.icon-speed-up-fill:before {
  content: "\e9eb";
}

.icon-elevation:before {
  content: "\e9ec";
}

.icon-user-help:before {
  content: "\e9ed";
}

.icon-helicopter:before {
  content: "\e9ee";
}

.icon-car:before {
  content: "\e9ef";
}

.icon-team:before {
  content: "\e9f0";
}

.icon-nurse:before {
  content: "\e9f1";
}

.icon-sailboat:before {
  content: "\e9f2";
}

.icon-drop:before {
  content: "\e9f3";
}

.icon-bubble-chart:before {
  content: "\e9f4";
}

.icon-camera-switch-line:before {
  content: "\e9f5";
}

.icon-zoom-in-line:before {
  content: "\e9f6";
}

.icon-map:before {
  content: "\e9f7";
}

.icon-finish:before {
  content: "\e9f8";
}

.icon-shapes:before {
  content: "\e9f9";
}

.icon-science:before {
  content: "\e9fa";
}

.icon-todo:before {
  content: "\e9fb";
}

.icon-tools:before {
  content: "\e9fc";
}

.icon-contract:before {
  content: "\e9fd";
}

.icon-wifi-off:before {
  content: "\e9fe";
}

.icon-signal-tower-fill:before {
  content: "\e9ff";
}

.icon-hems-emr:before {
  content: "\ea00";
}

.icon-tree-fill:before {
  content: "\ea01";
}

.icon-plane-airport-fill:before {
  content: "\ea02";
}

.icon-helicopter-lpr-fill:before {
  content: "\ea03";
}

.icon-barricade-fill:before {
  content: "\ea04";
}

.icon-stack-fill:before {
  content: "\ea05";
}

.icon-text-block-complex-1b:before {
  content: "\ea06";
}

.icon-text-block-complex-1a:before {
  content: "\ea07";
}

.icon-text-block-complex-2b:before {
  content: "\ea08";
}

.icon-team-fill-complex-1b:before {
  content: "\ea09";
}

.icon-team-fill:before {
  content: "\ea0a";
}

.icon-text-block-complex-2a:before {
  content: "\ea0b";
}

.icon-team-fill-complex-1a:before {
  content: "\ea0c";
}

.icon-global-poland:before {
  content: "\ea0d";
}

.icon-arrow-up-single:before {
  content: "\ea0e";
}

.icon-arrow-up-triple:before {
  content: "\ea0f";
}

.icon-hems:before {
  content: "\ea10";
}

.icon-lifebuoy:before {
  content: "\ea11";
}

.icon-file-details:before {
  content: "\ea12";
}

.icon-alert-fill:before {
  content: "\ea13";
}

.icon-alert:before {
  content: "\ea14";
}

.icon-flag:before {
  content: "\ea15";
}

.icon-map-unpin:before {
  content: "\ea16";
}

.icon-id-card:before {
  content: "\ea17";
}

.icon-flag-fill:before {
  content: "\ea18";
}

.icon-cylinder-question:before {
  content: "\ea19";
}

.icon-map-info:before {
  content: "\ea1a";
}

.icon-coins:before {
  content: "\ea1b";
}

.icon-magic:before {
  content: "\ea1c";
}

.icon-bank-card:before {
  content: "\ea1d";
}

.icon-pencil:before {
  content: "\ea1e";
}

.icon-booklet:before {
  content: "\ea1f";
}

.icon-draw-arrow-straight:before {
  content: "\ea20";
}

.icon-swap-box:before {
  content: "\ea21";
}

.icon-stack-line:before {
  content: "\ea22";
}

.icon-fire:before {
  content: "\ea23";
}

.icon-star-fill:before {
  content: "\ea24";
}

.icon-upload:before {
  content: "\ea25";
}

.icon-markpen:before {
  content: "\ea26";
}

.icon-drag-drop:before {
  content: "\ea27";
}

.icon-share:before {
  content: "\ea28";
}

.icon-diploma:before {
  content: "\ea29";
}

.icon-mail-notification-1:before {
  content: "\ea2a";
}

.icon-mail-notification-2:before {
  content: "\ea2b";
}

.icon-exchange-funds-line:before {
  content: "\ea2c";
}

.icon-map-pin-accepted:before {
  content: "\ea2d";
}

.icon-equalizer-fill:before {
  content: "\ea2e";
}

.icon-user-follow-fill:before {
  content: "\ea2f";
}

.icon-mail-send-fill:before {
  content: "\ea30";
}

.icon-archive-fill:before {
  content: "\ea31";
}

.icon-calendar-event-fill:before {
  content: "\ea32";
}

.icon-waypoint:before {
  content: "\ea33";
}

.icon-airstrip:before {
  content: "\ea34";
}

.icon-flyeye:before {
  content: "\ea35";
}

.icon-status-online-offline:before {
  content: "\ea36";
}

.icon-window-fill:before {
  content: "\ea37";
}

.icon-calendar-check-fill:before {
  content: "\ea38";
}

.icon-calendar-close-fill:before {
  content: "\ea39";
}

.icon-pause-circle-fill:before {
  content: "\ea3a";
}

.icon-notification-2-fill:before {
  content: "\ea3b";
}

.icon-arrow-up-circle-fill:before {
  content: "\ea3c";
}

.icon-arrow-down-circle-fill:before {
  content: "\ea3d";
}

.icon-first-aid-kit-fill:before {
  content: "\ea3e";
}

.icon-treasure-map-fill:before {
  content: "\ea3f";
}

.icon-hourglass-fill:before {
  content: "\ea40";
}

.icon-window-check-fill:before {
  content: "\ea41";
}

.icon-drone-hidden:before {
  content: "\ea42";
}

.icon-north:before {
  content: "\ea43";
}

.icon-focus-2-fill:before {
  content: "\ea44";
}

.icon-timer-flash-fill:before {
  content: "\ea45";
}

.icon-teenager:before {
  content: "\ea46";
}

.icon-briefcase:before {
  content: "\ea47";
}

.icon-spy-fill:before {
  content: "\ea48";
}

.icon-spy:before {
  content: "\ea49";
}

.icon-shape-freehand:before {
  content: "\ea4a";
}

.icon-first-aid-kit-line:before {
  content: "\ea4b";
}

.icon-collage:before {
  content: "\ea4c";
}

.icon-shape-irregular:before {
  content: "\ea4d";
}

.icon-ruler:before {
  content: "\ea4e";
}

.icon-tower:before {
  content: "\ea4f";
}

.icon-drone-pilot:before {
  content: "\ea50";
}

.icon-time-utc:before {
  content: "\ea51";
}

.icon-settings-fill:before {
  content: "\ea52";
}

.icon-volume-up-fill:before {
  content: "\ea53";
}

.icon-timer-line:before {
  content: "\ea54";
}

.icon-contrast:before {
  content: "\ea55";
}

.icon-sun-fill:before {
  content: "\ea56";
}

.icon-moon-fill:before {
  content: "\ea57";
}

.icon-polygon-dashed:before {
  content: "\ea58";
}

.icon-book-cover:before {
  content: "\ea59";
}

.icon-message:before {
  content: "\ea5a";
}

.icon-enlarge:before {
  content: "\ea5b";
}

.icon-arrow-down-4:before {
  content: "\ea5c";
}

/**
 * Make by Icon Font Exporter
 * https://www.figma.com/community/plugin/1129455674275940478/Icon-Font-Exporter
 */
.iconfont {
  font-size: 1.25rem;
  line-height: 1em;
  font-weight: 500;
  --complex-icon-main-color: #061636;
  --complex-icon-accent-color: #1d97fc;
}

.icon-complex-altitude-range {
  position: relative;
}
.icon-complex-altitude-range::before {
  position: absolute;
  content: "\e99c";
  color: var(--complex-icon-accent-color);
}
.icon-complex-altitude-range::after {
  content: "\e9a0";
  color: var(--complex-icon-main-color);
}

.icon-complex-altitude {
  position: relative;
}
.icon-complex-altitude::before {
  position: absolute;
  content: "\e9a7";
  color: var(--complex-icon-accent-color);
}
.icon-complex-altitude::after {
  content: "\e99f";
  color: var(--complex-icon-main-color);
}

.icon-complex-radius {
  position: relative;
}
.icon-complex-radius::before {
  position: absolute;
  content: "\e951";
  color: var(--complex-icon-main-color);
}
.icon-complex-radius::after {
  content: "\e952";
  color: var(--complex-icon-accent-color);
}

.icon-complex-height {
  position: relative;
}
.icon-complex-height::before {
  position: absolute;
  content: "\e99d";
  color: var(--complex-icon-main-color);
}
.icon-complex-height::after {
  content: "\e999";
  color: var(--complex-icon-accent-color);
}

.icon-complex-top-height {
  position: relative;
}
.icon-complex-top-height::before {
  position: absolute;
  content: "\e956";
  color: var(--complex-icon-accent-color);
}
.icon-complex-top-height::after {
  content: "\e955";
  color: var(--complex-icon-main-color);
}

.icon-complex-bottom-height {
  position: relative;
}
.icon-complex-bottom-height::before {
  position: absolute;
  content: "\e953";
  color: var(--complex-icon-accent-color);
}
.icon-complex-bottom-height::after {
  content: "\e954";
  color: var(--complex-icon-main-color);
}

.icon-complex-cylinder-point-left {
  position: relative;
  --complex-icon-accent-color: #007544;
}
.icon-complex-cylinder-point-left::before {
  position: absolute;
  content: "\e9a6";
  color: var(--complex-icon-accent-color);
}
.icon-complex-cylinder-point-left::after {
  content: "\e9a8";
  color: var(--complex-icon-main-color);
}

.icon-complex-cylinder-point-center {
  position: relative;
  --complex-icon-accent-color: #ff6f00;
}
.icon-complex-cylinder-point-center::before {
  position: absolute;
  content: "\e9a7";
  color: var(--complex-icon-accent-color);
}
.icon-complex-cylinder-point-center::after {
  content: "\e9a8";
  color: var(--complex-icon-main-color);
}

.icon-complex-cylinder-point-right {
  position: relative;
  --complex-icon-accent-color: #007544;
}
.icon-complex-cylinder-point-right::before {
  position: absolute;
  content: "\e9a5";
  color: var(--complex-icon-accent-color);
}
.icon-complex-cylinder-point-right::after {
  content: "\e9a8";
  color: var(--complex-icon-main-color);
}

.icon-complex-cylinder-top {
  position: relative;
  --complex-icon-accent-color: #ffecb3;
}
.icon-complex-cylinder-top::before {
  position: absolute;
  content: "\e9a8";
  color: var(--complex-icon-main-color);
}
.icon-complex-cylinder-top::after {
  content: "\e9aa";
  color: var(--complex-icon-accent-color);
}

.icon-complex-cylinder-bottom {
  position: relative;
  --complex-icon-accent-color: #ffecb3;
}
.icon-complex-cylinder-bottom::before {
  position: absolute;
  content: "\e9a8";
  color: var(--complex-icon-main-color);
}
.icon-complex-cylinder-bottom::after {
  content: "\e9ab";
  color: var(--complex-icon-accent-color);
}

.icon-complex-prism-top {
  position: relative;
  --complex-icon-accent-color: #ffecb3;
}
.icon-complex-prism-top::before {
  position: absolute;
  content: "\e9ac";
  color: var(--complex-icon-main-color);
}
.icon-complex-prism-top::after {
  content: "\e9ad";
  color: var(--complex-icon-accent-color);
}

.icon-complex-prism-bottom {
  position: relative;
  --complex-icon-accent-color: #ffecb3;
}
.icon-complex-prism-bottom::before {
  position: absolute;
  content: "\e9ac";
  color: var(--complex-icon-main-color);
}
.icon-complex-prism-bottom::after {
  content: "\e9ae";
  color: var(--complex-icon-accent-color);
}

.icon-complex-waypoint-edit {
  position: relative;
  --complex-icon-accent-color: #007544;
}
.icon-complex-waypoint-edit::before {
  position: absolute;
  content: "\e9a6";
  color: var(--complex-icon-accent-color);
}
.icon-complex-waypoint-edit::after {
  content: "\e9a9";
  color: var(--complex-icon-main-color);
}

.icon-complex-leaving-area {
  position: relative;
  --complex-icon-accent-color: #90cbfe;
}
.icon-complex-leaving-area::before {
  position: absolute;
  content: "\e9c2";
  color: var(--complex-icon-main-color);
}
.icon-complex-leaving-area::after {
  content: "\e9c3";
  color: var(--complex-icon-accent-color);
}

.icon-complex-mission-conflict {
  position: relative;
  --complex-icon-accent-color: #b00020;
}
.icon-complex-mission-conflict::before {
  position: absolute;
  content: "\e9c5";
  color: var(--complex-icon-main-color);
}
.icon-complex-mission-conflict::after {
  content: "\e9c4";
  color: var(--complex-icon-accent-color);
}

.icon-complex-cylinder-fill {
  position: relative;
  --complex-icon-accent-color: #ffecb3;
}
.icon-complex-cylinder-fill::before {
  position: absolute;
  content: "\e9e1";
  color: var(--complex-icon-main-color);
}
.icon-complex-cylinder-fill::after {
  content: "\e9d1";
  color: var(--complex-icon-accent-color);
}

.icon-complex-cylinder-fill-background {
  position: relative;
}
.icon-complex-cylinder-fill-background::before {
  position: absolute;
  content: "\e9e1";
  background-image: var(--complex-icon-main-image);
  background-clip: text;
  color: transparent;
}
.icon-complex-cylinder-fill-background::after {
  content: "\e9d1";
  background-image: var(--complex-icon-accent-image);
  background-clip: text;
  color: transparent;
}

.icon-complex-cylinder-dash-fill-top-down {
  position: relative;
  --complex-icon-accent-color: #ffecb3;
}
.icon-complex-cylinder-dash-fill-top-down::before {
  position: absolute;
  content: "\e9cf";
  color: var(--complex-icon-main-color);
}
.icon-complex-cylinder-dash-fill-top-down::after {
  content: "\e9d0";
  color: var(--complex-icon-accent-color);
}

.icon-complex-cylinder-fill-top-down {
  position: relative;
}
.icon-complex-cylinder-fill-top-down::before {
  position: absolute;
  content: "\e9e1";
  color: var(--complex-icon-main-color);
}
.icon-complex-cylinder-fill-top-down::after {
  content: "\e9d0";
  color: var(--complex-icon-accent-color);
}

.icon-complex-team-fill {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #223d6b;
}
.icon-complex-team-fill::before {
  position: absolute;
  content: "\ea09";
  color: var(--complex-icon-main-color);
}
.icon-complex-team-fill::after {
  content: "\ea0a";
  color: var(--complex-icon-accent-color);
}

.icon-complex-team-fill-less {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #8d99b1;
}
.icon-complex-team-fill-less::before {
  position: absolute;
  content: "\ea09";
  color: var(--complex-icon-main-color);
}
.icon-complex-team-fill-less::after {
  content: "\ea0a";
  color: var(--complex-icon-accent-color);
}

.icon-complex-text-block-off {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #8d99b1;
}
.icon-complex-text-block-off::before {
  position: absolute;
  content: "\ea07";
  color: var(--complex-icon-main-color);
}
.icon-complex-text-block-off::after {
  content: "\ea06";
  color: var(--complex-icon-accent-color);
}

.icon-complex-text-block {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #223d6b;
}
.icon-complex-text-block::before {
  position: absolute;
  content: "\ea0b";
  color: var(--complex-icon-main-color);
}
.icon-complex-text-block::after {
  content: "\ea08";
  color: var(--complex-icon-accent-color);
}

.icon-complex-text-block-on {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #007544;
}
.icon-complex-text-block-on::before {
  position: absolute;
  content: "\ea0b";
  color: var(--complex-icon-main-color);
}
.icon-complex-text-block-on::after {
  content: "\ea08";
  color: var(--complex-icon-accent-color);
}

.icon-complex-mail-notification {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #b00020;
}
.icon-complex-mail-notification::before {
  position: absolute;
  content: "\ea2a";
  color: var(--complex-icon-main-color);
}
.icon-complex-mail-notification::after {
  content: "\ea2b";
  color: var(--complex-icon-accent-color);
}

.icon-complex-helicopter {
  position: relative;
  --complex-icon-main-color: #223d6b;
  --complex-icon-accent-color: #ffd54f;
}
.icon-complex-helicopter::before {
  position: absolute;
  content: "\e9ee";
  color: var(--complex-icon-main-color);
}
.icon-complex-helicopter::after {
  content: "\e9b2";
  color: var(--complex-icon-accent-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #ffca28;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #1477d5;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #b00020;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffca28;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffca28;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1477d5;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1477d5;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1477d5;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1477d5;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b00020;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b00020;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Manrope, Arial, sans-serif;
  --mat-option-label-text-line-height: 160%;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Manrope, Arial, sans-serif;
  --mat-optgroup-label-text-line-height: 160%;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 500;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: Manrope, Arial, sans-serif;
  --mat-card-title-text-line-height: 160%;
  --mat-card-title-text-size: 1.25rem;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 700;
  --mat-card-subtitle-text-font: Manrope, Arial, sans-serif;
  --mat-card-subtitle-text-line-height: 140%;
  --mat-card-subtitle-text-size: 0.875rem;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ffca28;
  --mdc-linear-progress-track-color: rgba(255, 202, 40, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 202, 40, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 202, 40, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 202, 40, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 202, 40, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 202, 40, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1477d5;
  --mdc-linear-progress-track-color: rgba(20, 119, 213, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(20, 119, 213, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(20, 119, 213, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(20, 119, 213, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(20, 119, 213, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(20, 119, 213, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #b00020;
  --mdc-linear-progress-track-color: rgba(176, 0, 32, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(176, 0, 32, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(176, 0, 32, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(176, 0, 32, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(176, 0, 32, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(176, 0, 32, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Manrope, Arial, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 0.875rem;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-caret-color: #ffca28;
  --mdc-filled-text-field-focus-active-indicator-color: #ffca28;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 202, 40, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #b00020;
  --mdc-filled-text-field-error-label-text-color: #b00020;
  --mdc-filled-text-field-error-caret-color: #b00020;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #b00020;
  --mdc-filled-text-field-error-focus-active-indicator-color: #b00020;
  --mdc-filled-text-field-error-hover-active-indicator-color: #b00020;
  --mdc-outlined-text-field-caret-color: #ffca28;
  --mdc-outlined-text-field-focus-outline-color: #ffca28;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 202, 40, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #b00020;
  --mdc-outlined-text-field-error-focus-label-text-color: #b00020;
  --mdc-outlined-text-field-error-label-text-color: #b00020;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #b00020;
  --mdc-outlined-text-field-error-hover-outline-color: #b00020;
  --mdc-outlined-text-field-error-outline-color: #b00020;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #b00020);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(255, 202, 40, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(20, 119, 213, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(176, 0, 32, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1477d5;
  --mdc-filled-text-field-focus-active-indicator-color: #1477d5;
  --mdc-filled-text-field-focus-label-text-color: rgba(20, 119, 213, 0.87);
  --mdc-outlined-text-field-caret-color: #1477d5;
  --mdc-outlined-text-field-focus-outline-color: #1477d5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(20, 119, 213, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #b00020;
  --mdc-filled-text-field-focus-active-indicator-color: #b00020;
  --mdc-filled-text-field-focus-label-text-color: rgba(176, 0, 32, 0.87);
  --mdc-outlined-text-field-caret-color: #b00020;
  --mdc-outlined-text-field-focus-outline-color: #b00020;
  --mdc-outlined-text-field-focus-label-text-color: rgba(176, 0, 32, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mdc-filled-text-field-label-text-font: Manrope, Arial, sans-serif;
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 500;
  --mdc-outlined-text-field-label-text-font: Manrope, Arial, sans-serif;
  --mdc-outlined-text-field-label-text-size: 0.875rem;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mat-form-field-container-text-font: Manrope, Arial, sans-serif;
  --mat-form-field-container-text-line-height: 160%;
  --mat-form-field-container-text-size: 0.875rem;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 0.875rem;
  --mat-form-field-subscript-text-font: Manrope, Arial, sans-serif;
  --mat-form-field-subscript-text-line-height: 140%;
  --mat-form-field-subscript-text-size: 0.875rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 500;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 202, 40, 0.87);
  --mat-select-invalid-arrow-color: rgba(176, 0, 32, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(20, 119, 213, 0.87);
  --mat-select-invalid-arrow-color: rgba(176, 0, 32, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(176, 0, 32, 0.87);
  --mat-select-invalid-arrow-color: rgba(176, 0, 32, 0.87);
}

html {
  --mat-select-trigger-text-font: Manrope, Arial, sans-serif;
  --mat-select-trigger-text-line-height: 160%;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Manrope, Arial, sans-serif;
  --mdc-dialog-subhead-line-height: 160%;
  --mdc-dialog-subhead-size: 1.25rem;
  --mdc-dialog-subhead-weight: 700;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Manrope, Arial, sans-serif;
  --mdc-dialog-supporting-text-line-height: 160%;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #142e59;
  --mdc-chip-elevated-container-color: #ffca28;
  --mdc-chip-elevated-disabled-container-color: #ffca28;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #142e59;
  --mdc-chip-with-icon-icon-color: #142e59;
  --mdc-chip-with-icon-disabled-icon-color: #142e59;
  --mdc-chip-with-icon-selected-icon-color: #142e59;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #142e59;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #142e59;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1477d5;
  --mdc-chip-elevated-disabled-container-color: #1477d5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #b00020;
  --mdc-chip-elevated-disabled-container-color: #b00020;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Manrope, Arial, sans-serif;
  --mdc-chip-label-text-line-height: 160%;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 700;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #ffb300;
  --mdc-switch-selected-handle-color: #ffb300;
  --mdc-switch-selected-hover-state-layer-color: #ffb300;
  --mdc-switch-selected-pressed-state-layer-color: #ffb300;
  --mdc-switch-selected-focus-handle-color: #ff6f00;
  --mdc-switch-selected-hover-handle-color: #ff6f00;
  --mdc-switch-selected-pressed-handle-color: #ff6f00;
  --mdc-switch-selected-focus-track-color: #ffd54f;
  --mdc-switch-selected-hover-track-color: #ffd54f;
  --mdc-switch-selected-pressed-track-color: #ffd54f;
  --mdc-switch-selected-track-color: #ffd54f;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #1d88ee;
  --mdc-switch-selected-handle-color: #1d88ee;
  --mdc-switch-selected-hover-state-layer-color: #1d88ee;
  --mdc-switch-selected-pressed-state-layer-color: #1d88ee;
  --mdc-switch-selected-focus-handle-color: #0849a4;
  --mdc-switch-selected-hover-handle-color: #0849a4;
  --mdc-switch-selected-pressed-handle-color: #0849a4;
  --mdc-switch-selected-focus-track-color: #63b6fd;
  --mdc-switch-selected-hover-track-color: #63b6fd;
  --mdc-switch-selected-pressed-track-color: #63b6fd;
  --mdc-switch-selected-track-color: #63b6fd;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #860020;
  --mdc-switch-selected-handle-color: #860020;
  --mdc-switch-selected-hover-state-layer-color: #860020;
  --mdc-switch-selected-pressed-state-layer-color: #860020;
  --mdc-switch-selected-focus-handle-color: #860020;
  --mdc-switch-selected-hover-handle-color: #860020;
  --mdc-switch-selected-pressed-handle-color: #860020;
  --mdc-switch-selected-focus-track-color: #c84c62;
  --mdc-switch-selected-hover-track-color: #c84c62;
  --mdc-switch-selected-pressed-track-color: #c84c62;
  --mdc-switch-selected-track-color: #c84c62;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Manrope, Arial, sans-serif;
  --mat-slide-toggle-label-text-size: 0.875rem;
  --mat-slide-toggle-label-text-tracking: normal;
  --mat-slide-toggle-label-text-line-height: 160%;
  --mat-slide-toggle-label-text-weight: 700;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffca28;
  --mdc-radio-selected-hover-icon-color: #ffca28;
  --mdc-radio-selected-icon-color: #ffca28;
  --mdc-radio-selected-pressed-icon-color: #ffca28;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ffca28;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1477d5;
  --mdc-radio-selected-hover-icon-color: #1477d5;
  --mdc-radio-selected-icon-color: #1477d5;
  --mdc-radio-selected-pressed-icon-color: #1477d5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #1477d5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #b00020;
  --mdc-radio-selected-hover-icon-color: #b00020;
  --mdc-radio-selected-icon-color: #b00020;
  --mdc-radio-selected-pressed-icon-color: #b00020;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #b00020;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Manrope, Arial, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 160%);
  font-weight: var(--mdc-typography-body2-font-weight, 700);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ffca28;
  --mdc-slider-focus-handle-color: #ffca28;
  --mdc-slider-hover-handle-color: #ffca28;
  --mdc-slider-active-track-color: #ffca28;
  --mdc-slider-inactive-track-color: #ffca28;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ffca28;
  --mat-mdc-slider-ripple-color: #ffca28;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 202, 40, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 202, 40, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1477d5;
  --mdc-slider-focus-handle-color: #1477d5;
  --mdc-slider-hover-handle-color: #1477d5;
  --mdc-slider-active-track-color: #1477d5;
  --mdc-slider-inactive-track-color: #1477d5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1477d5;
  --mat-mdc-slider-ripple-color: #1477d5;
  --mat-mdc-slider-hover-ripple-color: rgba(20, 119, 213, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(20, 119, 213, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #b00020;
  --mdc-slider-focus-handle-color: #b00020;
  --mdc-slider-hover-handle-color: #b00020;
  --mdc-slider-active-track-color: #b00020;
  --mdc-slider-inactive-track-color: #b00020;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #b00020;
  --mat-mdc-slider-ripple-color: #b00020;
  --mat-mdc-slider-hover-ripple-color: rgba(176, 0, 32, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(176, 0, 32, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Manrope, Arial, sans-serif;
  --mdc-slider-label-label-text-size: 0.875rem;
  --mdc-slider-label-label-text-line-height: 140%;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Manrope, Arial, sans-serif;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 160%;
  --mat-menu-item-label-text-weight: 500;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffca28;
  --mdc-radio-selected-hover-icon-color: #ffca28;
  --mdc-radio-selected-icon-color: #ffca28;
  --mdc-radio-selected-pressed-icon-color: #ffca28;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1477d5;
  --mdc-radio-selected-hover-icon-color: #1477d5;
  --mdc-radio-selected-icon-color: #1477d5;
  --mdc-radio-selected-pressed-icon-color: #1477d5;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #b00020;
  --mdc-radio-selected-hover-icon-color: #b00020;
  --mdc-radio-selected-icon-color: #b00020;
  --mdc-radio-selected-pressed-icon-color: #b00020;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ffca28;
  --mdc-checkbox-selected-hover-icon-color: #ffca28;
  --mdc-checkbox-selected-icon-color: #ffca28;
  --mdc-checkbox-selected-pressed-icon-color: #ffca28;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffca28;
  --mdc-checkbox-selected-hover-state-layer-color: #ffca28;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffca28;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1477d5;
  --mdc-checkbox-selected-hover-icon-color: #1477d5;
  --mdc-checkbox-selected-icon-color: #1477d5;
  --mdc-checkbox-selected-pressed-icon-color: #1477d5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1477d5;
  --mdc-checkbox-selected-hover-state-layer-color: #1477d5;
  --mdc-checkbox-selected-pressed-state-layer-color: #1477d5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #b00020;
  --mdc-checkbox-selected-hover-icon-color: #b00020;
  --mdc-checkbox-selected-icon-color: #b00020;
  --mdc-checkbox-selected-pressed-icon-color: #b00020;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #b00020;
  --mdc-checkbox-selected-hover-state-layer-color: #b00020;
  --mdc-checkbox-selected-pressed-state-layer-color: #b00020;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ffca28;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ffca28;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Manrope, Arial, sans-serif;
  --mdc-list-list-item-label-text-line-height: 160%;
  --mdc-list-list-item-label-text-size: 0.875rem;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Manrope, Arial, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 160%;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 700;
  --mdc-list-list-item-trailing-supporting-text-font: Manrope, Arial, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 140%;
  --mdc-list-list-item-trailing-supporting-text-size: 0.875rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
}

.mdc-list-group__subheader {
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  font-family: Manrope, Arial, sans-serif;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: Manrope, Arial, sans-serif;
  --mat-paginator-container-text-line-height: 140%;
  --mat-paginator-container-text-size: 0.875rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 500;
  --mat-paginator-select-trigger-text-size: 0.875rem;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ffca28;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ffca28;
  --mat-tab-header-active-ripple-color: #ffca28;
  --mat-tab-header-inactive-ripple-color: #ffca28;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ffca28;
  --mat-tab-header-active-hover-label-text-color: #ffca28;
  --mat-tab-header-active-focus-indicator-color: #ffca28;
  --mat-tab-header-active-hover-indicator-color: #ffca28;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1477d5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #1477d5;
  --mat-tab-header-active-ripple-color: #1477d5;
  --mat-tab-header-inactive-ripple-color: #1477d5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1477d5;
  --mat-tab-header-active-hover-label-text-color: #1477d5;
  --mat-tab-header-active-focus-indicator-color: #1477d5;
  --mat-tab-header-active-hover-indicator-color: #1477d5;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #b00020;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #b00020;
  --mat-tab-header-active-ripple-color: #b00020;
  --mat-tab-header-inactive-ripple-color: #b00020;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #b00020;
  --mat-tab-header-active-hover-label-text-color: #b00020;
  --mat-tab-header-active-focus-indicator-color: #b00020;
  --mat-tab-header-active-hover-indicator-color: #b00020;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ffca28;
  --mat-tab-header-with-background-foreground-color: #142e59;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1477d5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #b00020;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Manrope, Arial, sans-serif;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 140%;
  --mat-tab-header-label-text-weight: 700;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1477d5;
  --mdc-checkbox-selected-hover-icon-color: #1477d5;
  --mdc-checkbox-selected-icon-color: #1477d5;
  --mdc-checkbox-selected-pressed-icon-color: #1477d5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1477d5;
  --mdc-checkbox-selected-hover-state-layer-color: #1477d5;
  --mdc-checkbox-selected-pressed-state-layer-color: #1477d5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ffca28;
  --mdc-checkbox-selected-hover-icon-color: #ffca28;
  --mdc-checkbox-selected-icon-color: #ffca28;
  --mdc-checkbox-selected-pressed-icon-color: #ffca28;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffca28;
  --mdc-checkbox-selected-hover-state-layer-color: #ffca28;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffca28;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #b00020;
  --mdc-checkbox-selected-hover-icon-color: #b00020;
  --mdc-checkbox-selected-icon-color: #b00020;
  --mdc-checkbox-selected-pressed-icon-color: #b00020;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #b00020;
  --mdc-checkbox-selected-hover-state-layer-color: #b00020;
  --mdc-checkbox-selected-pressed-state-layer-color: #b00020;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Manrope, Arial, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 160%);
  font-weight: var(--mdc-typography-body2-font-weight, 700);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ffca28;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1477d5;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #b00020;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ffca28;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1477d5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #b00020;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ffca28;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1477d5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #b00020;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ffca28;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1477d5;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #b00020;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #ffca28;
  --mat-mdc-button-ripple-color: rgba(255, 202, 40, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1477d5;
  --mat-mdc-button-ripple-color: rgba(20, 119, 213, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Manrope, Arial, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: var(--mdc-typography-button-line-height, 140%);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ffca28;
  --mat-mdc-button-persistent-ripple-color: #ffca28;
  --mat-mdc-button-ripple-color: rgba(255, 202, 40, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1477d5;
  --mat-mdc-button-persistent-ripple-color: #1477d5;
  --mat-mdc-button-ripple-color: rgba(20, 119, 213, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #b00020;
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #ffca28;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1477d5;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #b00020;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Manrope, Arial, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: var(--mdc-typography-button-line-height, 140%);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Manrope, Arial, sans-serif;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 700;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1477d5;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Manrope, Arial, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 160%;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 700;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Manrope, Arial, sans-serif;
  --mat-table-header-headline-line-height: 140%;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Manrope, Arial, sans-serif;
  --mat-table-row-item-label-text-line-height: 160%;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 700;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Manrope, Arial, sans-serif;
  --mat-table-footer-supporting-text-line-height: 160%;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 700;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #ffca28;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1477d5;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #b00020;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #ffca28;
  --mat-badge-text-color: #142e59;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #1477d5;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #b00020;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Manrope, Arial, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Manrope, Arial, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 160%;
  --mat-bottom-sheet-container-text-size: 0.875rem;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 700;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: Manrope, Arial, sans-serif;
  --mat-standard-button-toggle-text-font: Manrope, Arial, sans-serif;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: #142e59;
  --mat-datepicker-calendar-date-selected-state-background-color: #ffca28;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 202, 40, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #142e59;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 202, 40, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 202, 40, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ffca28;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 202, 40, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1477d5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(20, 119, 213, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(20, 119, 213, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(20, 119, 213, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(20, 119, 213, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #b00020;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(176, 0, 32, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(176, 0, 32, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(176, 0, 32, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(176, 0, 32, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1477d5;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #b00020;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Manrope, Arial, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 700;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 700;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 700;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Manrope, Arial, sans-serif;
  --mat-expansion-header-text-size: 0.875rem;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Manrope, Arial, sans-serif;
  --mat-expansion-container-text-line-height: 160%;
  --mat-expansion-container-text-size: 0.875rem;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 700;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 0.875rem;
  --mat-grid-list-tile-header-secondary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-secondary-text-size: 0.875rem;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #ffca28;
}
.mat-icon.mat-accent {
  --mat-icon-color: #1477d5;
}
.mat-icon.mat-warn {
  --mat-icon-color: #b00020;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: #142e59;
  --mat-stepper-header-selected-state-icon-background-color: #ffca28;
  --mat-stepper-header-selected-state-icon-foreground-color: #142e59;
  --mat-stepper-header-done-state-icon-background-color: #ffca28;
  --mat-stepper-header-done-state-icon-foreground-color: #142e59;
  --mat-stepper-header-edit-state-icon-background-color: #ffca28;
  --mat-stepper-header-edit-state-icon-foreground-color: #142e59;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #b00020;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #b00020;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1477d5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1477d5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1477d5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #b00020;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #b00020;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #b00020;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Manrope, Arial, sans-serif;
  --mat-stepper-header-label-text-font: Manrope, Arial, sans-serif;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 700;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ffca28;
  --mat-toolbar-container-text-color: #142e59;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1477d5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #b00020;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Manrope, Arial, sans-serif;
  --mat-toolbar-title-text-line-height: 160%;
  --mat-toolbar-title-text-size: 1.25rem;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 700;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Manrope, Arial, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 700;
  font-size: 0.875rem;
}

@supports not selector(::-webkit-scrollbar) {
  :root {
    scrollbar-width: thin;
    scrollbar-color: #b9c1d0 transparent;
  }
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #b9c1d0;
  border-radius: 32px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8d99b1;
}
::-webkit-scrollbar-thumb:active {
  background: #637292;
}

.mat-dialog-content,
.scroll-shadows {
  background: linear-gradient(var(--scroll-shadows-background-color, white) 100%, rgba(255, 255, 255, 0)) center top, linear-gradient(rgba(255, 255, 255, 0), var(--scroll-shadows-background-color, white) 100%) center bottom, radial-gradient(farthest-side at 50% 0, rgba(34, 61, 107, 0.08), rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%, rgba(34, 61, 107, 0.08), rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size: 179% 15px, 163% 40px, 670% 15px, 223% 15px;
  background-attachment: local, local, scroll, scroll;
}
.mat-dialog-content::-webkit-scrollbar-track,
.scroll-shadows::-webkit-scrollbar-track {
  background: radial-gradient(var(--scroll-shadows-background-color, white), transparent);
}

html {
  height: 100vh;
  width: 100vw;
}

body {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
  height: 100vh;
  width: 100vw;
  background-color: #e3e6ec;
  font-family: Manrope, Arial, sans-serif;
  color: #061636;
}

button {
  font-family: Manrope, Arial, sans-serif;
}

.button-warn, .button-warn-small, .button-tertiary, .button-tertiary-small, .button-link, .button-link-small, .button-secondary, .button-icon, .button-icon-xsmall, .button-icon-small, .button-secondary-small, .button-primary, .button-primary-small {
  font-size: var(--button-font-size, 0.875rem);
  line-height: var(--button-line-height, 140%);
  font-weight: 700;
  font-family: inherit;
  padding: 10px 24px;
  border-radius: 4px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
[disabled].button-warn, [disabled].button-warn-small, [disabled].button-tertiary, [disabled].button-tertiary-small, [disabled].button-link, [disabled].button-link-small, [disabled].button-secondary, [disabled].button-icon, [disabled].button-icon-xsmall, [disabled].button-icon-small, [disabled].button-secondary-small, [disabled].button-primary, [disabled].button-primary-small {
  opacity: 0.5;
  cursor: default;
}
.button-warn:focus-visible, .button-warn-small:focus-visible, .button-tertiary:focus-visible, .button-tertiary-small:focus-visible, .button-link:focus-visible, .button-link-small:focus-visible, .button-secondary:focus-visible, .button-icon:focus-visible, .button-icon-xsmall:focus-visible, .button-icon-small:focus-visible, .button-secondary-small:focus-visible, .button-primary:focus-visible, .button-primary-small:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: -2px;
}
.button-warn dtm-ui-icon, .button-warn-small dtm-ui-icon, .button-tertiary dtm-ui-icon, .button-tertiary-small dtm-ui-icon, .button-link dtm-ui-icon, .button-link-small dtm-ui-icon, .button-secondary dtm-ui-icon, .button-icon dtm-ui-icon, .button-icon-xsmall dtm-ui-icon, .button-icon-small dtm-ui-icon, .button-secondary-small dtm-ui-icon, .button-primary dtm-ui-icon, .button-primary-small dtm-ui-icon {
  font-weight: 500;
}

.button-primary, .button-primary-small {
  background-color: #ffca28;
  color: #142e59;
}
.button-primary:hover:not([disabled]), .button-primary-small:hover:not([disabled]) {
  background-color: #ffb300;
}
.button-primary .mat-spinner circle, .button-primary-small .mat-spinner circle {
  stroke: #142e59;
}
.button-primary-small {
  padding: 6px 16px;
}

.button-secondary, .button-icon, .button-icon-xsmall, .button-icon-small, .button-secondary-small {
  color: #1477d5;
  padding-left: 16px;
  padding-right: 16px;
}
.button-secondary:hover:not([disabled]), .button-icon:hover:not([disabled]), .button-icon-xsmall:hover:not([disabled]), .button-icon-small:hover:not([disabled]), .button-secondary-small:hover:not([disabled]) {
  background-color: #e3f2fe;
}
.button-secondary .mat-spinner circle, .button-icon .mat-spinner circle, .button-icon-xsmall .mat-spinner circle, .button-icon-small .mat-spinner circle, .button-secondary-small .mat-spinner circle {
  stroke: #1477d5;
}
.button-secondary-small {
  padding: 6px 16px;
}

.button-link, .button-link-small {
  color: #1477d5;
  padding-inline: 4px;
  text-decoration: underline;
  font-weight: 500;
}
.button-link:hover:not([disabled]), .button-link-small:hover:not([disabled]) {
  background-color: #e3f2fe;
}
.button-link-small {
  padding: 0 2px;
}
.button-link-small:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: 0;
}

.button-tertiary, .button-tertiary-small {
  color: #637292;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: underline;
}
.button-tertiary:hover:not([disabled]), .button-tertiary-small:hover:not([disabled]) {
  color: #142e59;
}
.button-tertiary .mat-spinner circle, .button-tertiary-small .mat-spinner circle {
  stroke: #637292;
}
.button-tertiary-small {
  padding: 6px 16px;
}
.button-tertiary:has(> dtm-ui-icon), .button-tertiary-small:has(> dtm-ui-icon) {
  text-decoration: none;
}
.button-tertiary:has(> dtm-ui-icon) > :not(dtm-ui-icon), .button-tertiary-small:has(> dtm-ui-icon) > :not(dtm-ui-icon) {
  text-decoration: underline;
}

.button-warn, .button-warn-small {
  background: #b00020;
  color: #fff;
}
.button-warn:hover:not([disabled]), .button-warn-small:hover:not([disabled]) {
  background-color: #8e001a;
}
.button-warn .mat-spinner circle, .button-warn-small .mat-spinner circle {
  stroke: #637292;
}
.button-warn-small {
  padding: 6px 16px;
}

.button-icon, .button-icon-xsmall, .button-icon-small {
  border-radius: 50%;
  padding: 10px;
  line-height: 1;
  font-size: 1.25rem;
  font-weight: 500;
}
.button-icon-small {
  padding: 6px;
}
.button-icon-xsmall {
  padding: 2px;
}

html {
  --grid-max-grid-width: 1124px;
}

.grid {
  --content-padding-width: var(--content-margin-width);
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-auto-flow: row;
  gap: var(--grid-gutter-width);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--grid-max-grid-width, 100%);
  transition: width 300ms;
  padding-bottom: var(--grid-padding-bottom, var(--content-padding-width));
}

@media screen and (max-width: 430.98px) {
  html {
    --grid-gutter-width: 16px;
    --grid-margin-width: 16px;
  }
  .grid {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid > * {
    grid-column: span 6;
  }
  .grid > .grid-col-smartphone-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-smartphone-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-1 > .grid-col-smartphone-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > .grid-col-smartphone-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > .grid-col-smartphone-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-smartphone-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > .grid-col-smartphone-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-smartphone-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > .grid-col-smartphone-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-smartphone-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > .grid-col-smartphone-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-smartphone-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-smartphone-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-1 > .grid-col-smartphone-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > .grid-col-smartphone-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > .grid-col-smartphone-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-smartphone-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > .grid-col-smartphone-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-smartphone-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > .grid-col-smartphone-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-smartphone-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > .grid-col-smartphone-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-smartphone-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-smartphone-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-1 > .grid-col-smartphone-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-smartphone-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > .grid-col-smartphone-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > .grid-col-smartphone-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > .grid-col-smartphone-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > .grid-col-smartphone-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-smartphone-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > .grid-col-smartphone-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-smartphone-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-smartphone-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-1 > .grid-col-smartphone-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-smartphone-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > .grid-col-smartphone-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-smartphone-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > .grid-col-smartphone-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > .grid-col-smartphone-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > .grid-col-smartphone-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-smartphone-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > .grid-col-smartphone-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-smartphone-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-smartphone-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-1 > .grid-col-smartphone-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-smartphone-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > .grid-col-smartphone-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-smartphone-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > .grid-col-smartphone-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-smartphone-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > .grid-col-smartphone-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > .grid-col-smartphone-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > .grid-col-smartphone-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-smartphone-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-smartphone-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-1 > .grid-col-smartphone-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-smartphone-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-2 > .grid-col-smartphone-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-smartphone-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-3 > .grid-col-smartphone-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-smartphone-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-4 > .grid-col-smartphone-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-smartphone-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-5 > .grid-col-smartphone-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-6 > .grid-col-smartphone-6:first-child {
    grid-column: 1/span 6;
  }
}
@media screen and (min-width: 431px) and (max-width: 767.98px) {
  html {
    --grid-gutter-width: 16px;
    --grid-margin-width: 24px;
  }
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > * {
    grid-column: span 12;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-6:first-child {
    grid-column: 1/span 6;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-7 {
    grid-column: span 7;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 1/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-7:first-child {
    grid-column: 1/span 7;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-8 {
    grid-column: span 8;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 1/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-8:first-child {
    grid-column: 1/span 8;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-9 {
    grid-column: span 9;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 1/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-9:first-child {
    grid-column: 1/span 9;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-10 {
    grid-column: span 10;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 1/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-10:first-child {
    grid-column: 1/span 10;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-11 {
    grid-column: span 11;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-11:first-child {
    grid-column: 1/span 11;
  }
  .grid > .grid-col-smartphone-wide-0 {
    display: none;
  }
  .grid > .grid-col-smartphone-wide-12 {
    grid-column: span 12;
  }
  .grid.grid-maxsize-smartphone-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-smartphone-wide-1 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-smartphone-wide-2 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-smartphone-wide-3 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-smartphone-wide-4 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-smartphone-wide-5 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-smartphone-wide-6 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-smartphone-wide-7 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-smartphone-wide-8 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-smartphone-wide-9 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-smartphone-wide-10 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-smartphone-wide-11 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-smartphone-wide-12 > .grid-col-smartphone-wide-12:first-child {
    grid-column: 1/span 12;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024.98px) {
  html {
    --grid-gutter-width: 16px;
    --grid-margin-width: 24px;
  }
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > * {
    grid-column: span 12;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-6:first-child {
    grid-column: 1/span 6;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-7 {
    grid-column: span 7;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-7:first-child {
    grid-column: 1/span 7;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-7:first-child {
    grid-column: 1/span 7;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-8 {
    grid-column: span 8;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-8:first-child {
    grid-column: 1/span 8;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-8:first-child {
    grid-column: 1/span 8;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-9 {
    grid-column: span 9;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-9:first-child {
    grid-column: 1/span 9;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-9:first-child {
    grid-column: 1/span 9;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-10 {
    grid-column: span 10;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-10:first-child {
    grid-column: 1/span 10;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-10:first-child {
    grid-column: 1/span 10;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-11 {
    grid-column: span 11;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-11:first-child {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-11:first-child {
    grid-column: 1/span 11;
  }
  .grid > .grid-col-tablet-0 {
    display: none;
  }
  .grid > .grid-col-tablet-12 {
    grid-column: span 12;
  }
  .grid.grid-maxsize-tablet-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-tablet-1 > .grid-col-tablet-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-tablet-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-tablet-2 > .grid-col-tablet-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-tablet-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-tablet-3 > .grid-col-tablet-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-tablet-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-tablet-4 > .grid-col-tablet-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-tablet-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-tablet-5 > .grid-col-tablet-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-tablet-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-tablet-6 > .grid-col-tablet-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-tablet-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-tablet-7 > .grid-col-tablet-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-tablet-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-tablet-8 > .grid-col-tablet-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-tablet-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-tablet-9 > .grid-col-tablet-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-tablet-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-tablet-10 > .grid-col-tablet-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-tablet-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-tablet-11 > .grid-col-tablet-12:first-child {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-tablet-12 > .grid-col-tablet-12:first-child {
    grid-column: 1/span 12;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1439.98px) {
  html {
    --grid-gutter-width: 24px;
    --grid-margin-width: 24px;
  }
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > * {
    grid-column: span 12;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-6:first-child {
    grid-column: 1/span 6;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-7 {
    grid-column: span 7;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-7:first-child {
    grid-column: 1/span 7;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-7:first-child {
    grid-column: 1/span 7;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-8 {
    grid-column: span 8;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-8:first-child {
    grid-column: 1/span 8;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-8:first-child {
    grid-column: 1/span 8;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-9 {
    grid-column: span 9;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-9:first-child {
    grid-column: 1/span 9;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-9:first-child {
    grid-column: 1/span 9;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-10 {
    grid-column: span 10;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-10:first-child {
    grid-column: 1/span 10;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-10:first-child {
    grid-column: 1/span 10;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-11 {
    grid-column: span 11;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-11:first-child {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-11:first-child {
    grid-column: 1/span 11;
  }
  .grid > .grid-col-desktop-0 {
    display: none;
  }
  .grid > .grid-col-desktop-12 {
    grid-column: span 12;
  }
  .grid.grid-maxsize-desktop-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-1 > .grid-col-desktop-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-desktop-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-2 > .grid-col-desktop-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-desktop-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-3 > .grid-col-desktop-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-desktop-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-4 > .grid-col-desktop-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-desktop-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-5 > .grid-col-desktop-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-desktop-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-6 > .grid-col-desktop-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-desktop-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-7 > .grid-col-desktop-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-desktop-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-8 > .grid-col-desktop-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-desktop-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-9 > .grid-col-desktop-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-desktop-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-10 > .grid-col-desktop-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-desktop-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-11 > .grid-col-desktop-12:first-child {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-12 > .grid-col-desktop-12:first-child {
    grid-column: 1/span 12;
  }
}
@media screen and (min-width: 1440px) {
  html {
    --grid-gutter-width: 24px;
    --grid-margin-width: auto;
  }
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > * {
    grid-column: span 12;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-6:first-child {
    grid-column: 1/span 6;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-7 {
    grid-column: span 7;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-7:first-child {
    grid-column: 1/span 7;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-7:first-child {
    grid-column: 1/span 7;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-8 {
    grid-column: span 8;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-8:first-child {
    grid-column: 1/span 8;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-8:first-child {
    grid-column: 1/span 8;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-9 {
    grid-column: span 9;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-9:first-child {
    grid-column: 1/span 9;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-9:first-child {
    grid-column: 1/span 9;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-10 {
    grid-column: span 10;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-10:first-child {
    grid-column: 1/span 10;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-10:first-child {
    grid-column: 1/span 10;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-11 {
    grid-column: span 11;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-11:first-child {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-11:first-child {
    grid-column: 1/span 11;
  }
  .grid > .grid-col-desktop-wide-0 {
    display: none;
  }
  .grid > .grid-col-desktop-wide-12 {
    grid-column: span 12;
  }
  .grid.grid-maxsize-desktop-wide-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-desktop-wide-1 > .grid-col-desktop-wide-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-desktop-wide-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-desktop-wide-2 > .grid-col-desktop-wide-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-desktop-wide-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-desktop-wide-3 > .grid-col-desktop-wide-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-desktop-wide-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-desktop-wide-4 > .grid-col-desktop-wide-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-desktop-wide-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-desktop-wide-5 > .grid-col-desktop-wide-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-desktop-wide-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-desktop-wide-6 > .grid-col-desktop-wide-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-desktop-wide-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-desktop-wide-7 > .grid-col-desktop-wide-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-desktop-wide-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-desktop-wide-8 > .grid-col-desktop-wide-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-desktop-wide-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-desktop-wide-9 > .grid-col-desktop-wide-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-desktop-wide-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-desktop-wide-10 > .grid-col-desktop-wide-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-desktop-wide-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-desktop-wide-11 > .grid-col-desktop-wide-12:first-child {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-desktop-wide-12 > .grid-col-desktop-wide-12:first-child {
    grid-column: 1/span 12;
  }
}
@media screen and (max-width: 430.98px) {
  .grid > .grid-col-sm-0 {
    display: none;
  }
  .grid > .grid-col-sm-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-sm-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-1 > .grid-col-sm-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > .grid-col-sm-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-3 > .grid-col-sm-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-sm-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > .grid-col-sm-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-sm-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-5 > .grid-col-sm-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-sm-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > .grid-col-sm-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-sm-0 {
    display: none;
  }
  .grid > .grid-col-sm-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-sm-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-1 > .grid-col-sm-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > .grid-col-sm-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-3 > .grid-col-sm-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-sm-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > .grid-col-sm-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-sm-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-5 > .grid-col-sm-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-sm-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > .grid-col-sm-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-sm-0 {
    display: none;
  }
  .grid > .grid-col-sm-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-sm-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-1 > .grid-col-sm-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-sm-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > .grid-col-sm-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-sm-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-3 > .grid-col-sm-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > .grid-col-sm-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-5 > .grid-col-sm-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-sm-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > .grid-col-sm-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-sm-0 {
    display: none;
  }
  .grid > .grid-col-sm-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-sm-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-1 > .grid-col-sm-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-sm-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > .grid-col-sm-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-sm-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-3 > .grid-col-sm-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > .grid-col-sm-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-5 > .grid-col-sm-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-sm-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > .grid-col-sm-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-sm-0 {
    display: none;
  }
  .grid > .grid-col-sm-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-sm-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-1 > .grid-col-sm-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-sm-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > .grid-col-sm-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-sm-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-3 > .grid-col-sm-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-sm-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > .grid-col-sm-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-sm-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-5 > .grid-col-sm-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > .grid-col-sm-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-sm-0 {
    display: none;
  }
  .grid > .grid-col-sm-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-sm-1 > * {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-sm-1 > .grid-col-sm-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-sm-2 > * {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-sm-2 > .grid-col-sm-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-sm-3 > * {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-sm-3 > .grid-col-sm-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-sm-4 > * {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-sm-4 > .grid-col-sm-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-sm-5 > * {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-sm-5 > .grid-col-sm-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > * {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-sm-6 > .grid-col-sm-6:first-child {
    grid-column: 1/span 6;
  }
}
@media screen and (min-width: 431px) and (max-width: 767.98px), screen and (min-width: 768px) and (max-width: 1024.98px) {
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-6:first-child {
    grid-column: 1/span 6;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-7 {
    grid-column: span 7;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-7:first-child {
    grid-column: 1/span 7;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-7:first-child {
    grid-column: 1/span 7;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-8 {
    grid-column: span 8;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-8:first-child {
    grid-column: 1/span 8;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-8:first-child {
    grid-column: 1/span 8;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-9 {
    grid-column: span 9;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-9:first-child {
    grid-column: 1/span 9;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-9:first-child {
    grid-column: 1/span 9;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-10 {
    grid-column: span 10;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-10:first-child {
    grid-column: 1/span 10;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-10:first-child {
    grid-column: 1/span 10;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-11 {
    grid-column: span 11;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-11:first-child {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-11:first-child {
    grid-column: 1/span 11;
  }
  .grid > .grid-col-md-0 {
    display: none;
  }
  .grid > .grid-col-md-12 {
    grid-column: span 12;
  }
  .grid.grid-maxsize-md-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-md-1 > .grid-col-md-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-md-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-md-2 > .grid-col-md-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-md-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-md-3 > .grid-col-md-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-md-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-md-4 > .grid-col-md-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-md-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-md-5 > .grid-col-md-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-md-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-md-6 > .grid-col-md-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-md-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-md-7 > .grid-col-md-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-md-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-md-8 > .grid-col-md-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-md-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-md-9 > .grid-col-md-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-md-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-md-10 > .grid-col-md-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-md-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-md-11 > .grid-col-md-12:first-child {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-md-12 > .grid-col-md-12:first-child {
    grid-column: 1/span 12;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1439.98px), screen and (min-width: 1440px) {
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-1 {
    grid-column: span 1;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-1:first-child {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-1:first-child {
    grid-column: 5/span 1;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-1:first-child {
    grid-column: 4/span 1;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-1:first-child {
    grid-column: 3/span 1;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-1:first-child {
    grid-column: 2/span 1;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-1:first-child {
    grid-column: 1/span 1;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-1:first-child {
    grid-column: 1/span 1;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-2 {
    grid-column: span 2;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-2:first-child {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-2:first-child {
    grid-column: 5/span 2;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-2:first-child {
    grid-column: 4/span 2;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-2:first-child {
    grid-column: 3/span 2;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-2:first-child {
    grid-column: 2/span 2;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-2:first-child {
    grid-column: 1/span 2;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-2:first-child {
    grid-column: 1/span 2;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-3 {
    grid-column: span 3;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-3:first-child {
    grid-column: 6/span 3;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-3:first-child {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-3:first-child {
    grid-column: 4/span 3;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-3:first-child {
    grid-column: 3/span 3;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-3:first-child {
    grid-column: 2/span 3;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-3:first-child {
    grid-column: 1/span 3;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-3:first-child {
    grid-column: 1/span 3;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-4 {
    grid-column: span 4;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-4:first-child {
    grid-column: 6/span 4;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-4:first-child {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-4:first-child {
    grid-column: 4/span 4;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-4:first-child {
    grid-column: 3/span 4;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-4:first-child {
    grid-column: 2/span 4;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-4:first-child {
    grid-column: 1/span 4;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-4:first-child {
    grid-column: 1/span 4;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-5 {
    grid-column: span 5;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-5:first-child {
    grid-column: 6/span 5;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-5:first-child {
    grid-column: 5/span 5;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-5:first-child {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-5:first-child {
    grid-column: 3/span 5;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-5:first-child {
    grid-column: 2/span 5;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-5:first-child {
    grid-column: 1/span 5;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-5:first-child {
    grid-column: 1/span 5;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-6 {
    grid-column: span 6;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-6:first-child {
    grid-column: 6/span 6;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-6:first-child {
    grid-column: 5/span 6;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-6:first-child {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-6:first-child {
    grid-column: 3/span 6;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-6:first-child {
    grid-column: 2/span 6;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-6:first-child {
    grid-column: 1/span 6;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-6:first-child {
    grid-column: 1/span 6;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-7 {
    grid-column: span 7;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-7:first-child {
    grid-column: 6/span 7;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-7:first-child {
    grid-column: 5/span 7;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-7:first-child {
    grid-column: 4/span 7;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-7:first-child {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-7:first-child {
    grid-column: 2/span 7;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-7:first-child {
    grid-column: 1/span 7;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-7:first-child {
    grid-column: 1/span 7;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-8 {
    grid-column: span 8;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-8:first-child {
    grid-column: 6/span 8;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-8:first-child {
    grid-column: 5/span 8;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-8:first-child {
    grid-column: 4/span 8;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-8:first-child {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-8:first-child {
    grid-column: 2/span 8;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-8:first-child {
    grid-column: 1/span 8;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-8:first-child {
    grid-column: 1/span 8;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-9 {
    grid-column: span 9;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-9:first-child {
    grid-column: 6/span 9;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-9:first-child {
    grid-column: 5/span 9;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-9:first-child {
    grid-column: 4/span 9;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-9:first-child {
    grid-column: 3/span 9;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-9:first-child {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-9:first-child {
    grid-column: 1/span 9;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-9:first-child {
    grid-column: 1/span 9;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-10 {
    grid-column: span 10;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-10:first-child {
    grid-column: 6/span 10;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-10:first-child {
    grid-column: 5/span 10;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-10:first-child {
    grid-column: 4/span 10;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-10:first-child {
    grid-column: 3/span 10;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-10:first-child {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-10:first-child {
    grid-column: 1/span 10;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-10:first-child {
    grid-column: 1/span 10;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-11 {
    grid-column: span 11;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-11:first-child {
    grid-column: 6/span 11;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-11:first-child {
    grid-column: 5/span 11;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-11:first-child {
    grid-column: 4/span 11;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-11:first-child {
    grid-column: 3/span 11;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-11:first-child {
    grid-column: 2/span 11;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-11:first-child {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-11:first-child {
    grid-column: 1/span 11;
  }
  .grid > .grid-col-0 {
    display: none;
  }
  .grid > .grid-col-12 {
    grid-column: span 12;
  }
  .grid.grid-maxsize-1 > * {
    grid-column: 6/span 1;
  }
  .grid.grid-maxsize-1 > .grid-col-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-2 > * {
    grid-column: 6/span 2;
  }
  .grid.grid-maxsize-2 > .grid-col-12:first-child {
    grid-column: 6/span 12;
  }
  .grid.grid-maxsize-3 > * {
    grid-column: 5/span 3;
  }
  .grid.grid-maxsize-3 > .grid-col-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-4 > * {
    grid-column: 5/span 4;
  }
  .grid.grid-maxsize-4 > .grid-col-12:first-child {
    grid-column: 5/span 12;
  }
  .grid.grid-maxsize-5 > * {
    grid-column: 4/span 5;
  }
  .grid.grid-maxsize-5 > .grid-col-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-6 > * {
    grid-column: 4/span 6;
  }
  .grid.grid-maxsize-6 > .grid-col-12:first-child {
    grid-column: 4/span 12;
  }
  .grid.grid-maxsize-7 > * {
    grid-column: 3/span 7;
  }
  .grid.grid-maxsize-7 > .grid-col-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-8 > * {
    grid-column: 3/span 8;
  }
  .grid.grid-maxsize-8 > .grid-col-12:first-child {
    grid-column: 3/span 12;
  }
  .grid.grid-maxsize-9 > * {
    grid-column: 2/span 9;
  }
  .grid.grid-maxsize-9 > .grid-col-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-10 > * {
    grid-column: 2/span 10;
  }
  .grid.grid-maxsize-10 > .grid-col-12:first-child {
    grid-column: 2/span 12;
  }
  .grid.grid-maxsize-11 > * {
    grid-column: 1/span 11;
  }
  .grid.grid-maxsize-11 > .grid-col-12:first-child {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > * {
    grid-column: 1/span 12;
  }
  .grid.grid-maxsize-12 > .grid-col-12:first-child {
    grid-column: 1/span 12;
  }
}
.mat-input-element {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  box-sizing: content-box;
}

.mat-input-element:-moz-ui-invalid {
  box-shadow: none;
}

.mat-input-element,
.mat-input-element::-webkit-search-cancel-button,
.mat-input-element::-webkit-search-decoration,
.mat-input-element::-webkit-search-results-button,
.mat-input-element::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.mat-input-element::-webkit-contacts-auto-fill-button,
.mat-input-element::-webkit-caps-lock-indicator,
.mat-input-element:not([type=password])::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

.mat-input-element[type=date],
.mat-input-element[type=datetime],
.mat-input-element[type=datetime-local],
.mat-input-element[type=month],
.mat-input-element[type=week],
.mat-input-element[type=time] {
  line-height: 1;
}

.mat-input-element[type=date]::after,
.mat-input-element[type=datetime]::after,
.mat-input-element[type=datetime-local]::after,
.mat-input-element[type=month]::after,
.mat-input-element[type=week]::after,
.mat-input-element[type=time]::after {
  content: " ";
  white-space: pre;
  width: 1px;
}

.mat-input-element::-webkit-inner-spin-button,
.mat-input-element::-webkit-calendar-picker-indicator,
.mat-input-element::-webkit-clear-button {
  font-size: 0.75em;
}

.mat-input-element::placeholder {
  -webkit-user-select: none;
  user-select: none;
  transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-input-element::-moz-placeholder {
  -webkit-user-select: none;
  user-select: none;
  transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-input-element::-webkit-input-placeholder {
  -webkit-user-select: none;
  user-select: none;
  transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-input-element:-ms-input-placeholder {
  -webkit-user-select: none;
  user-select: none;
  transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-form-field-hide-placeholder .mat-input-element::placeholder {
  color: transparent !important;
  -webkit-text-fill-color: transparent;
  transition: none;
}

.cdk-high-contrast-active .mat-form-field-hide-placeholder .mat-input-element::placeholder {
  opacity: 0;
}

.mat-form-field-hide-placeholder .mat-input-element::-moz-placeholder {
  color: transparent !important;
  -webkit-text-fill-color: transparent;
  transition: none;
}

.cdk-high-contrast-active .mat-form-field-hide-placeholder .mat-input-element::-moz-placeholder {
  opacity: 0;
}

.mat-form-field-hide-placeholder .mat-input-element::-webkit-input-placeholder {
  color: transparent !important;
  -webkit-text-fill-color: transparent;
  transition: none;
}

.cdk-high-contrast-active .mat-form-field-hide-placeholder .mat-input-element::-webkit-input-placeholder {
  opacity: 0;
}

.mat-form-field-hide-placeholder .mat-input-element:-ms-input-placeholder {
  color: transparent !important;
  -webkit-text-fill-color: transparent;
  transition: none;
}

.cdk-high-contrast-active .mat-form-field-hide-placeholder .mat-input-element:-ms-input-placeholder {
  opacity: 0;
}

textarea.mat-input-element {
  resize: vertical;
  overflow: auto;
}

textarea.mat-input-element.cdk-textarea-autosize {
  resize: none;
}

textarea.mat-input-element {
  padding: 2px 0;
  margin: -2px 0;
}

select.mat-input-element {
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  background-color: transparent;
  display: inline-flex;
  box-sizing: border-box;
  padding-top: 1em;
  top: -1em;
  margin-bottom: -1em;
}

select.mat-input-element::-moz-focus-inner {
  border: 0;
}

select.mat-input-element:not(:disabled) {
  cursor: pointer;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2.5px;
  pointer-events: none;
}

[dir=rtl] .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  right: auto;
  left: 0;
}

.mat-form-field-type-mat-native-select .mat-input-element {
  padding-right: 15px;
}

[dir=rtl] .mat-form-field-type-mat-native-select .mat-input-element {
  padding-right: 0;
  padding-left: 15px;
}

.mat-form-field-type-mat-native-select .mat-form-field-label-wrapper {
  max-width: calc(100% - 10px);
}

.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-infix::after {
  margin-top: -5px;
}

.mat-form-field-type-mat-native-select.mat-form-field-appearance-fill .mat-form-field-infix::after {
  margin-top: -10px;
}

.chip {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  border-radius: 32px;
  width: fit-content;
  border: 1px solid;
  word-break: normal;
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 600;
}
.chip dtm-ui-icon {
  margin-left: -4px;
}
.chip.error {
  background-color: #f2d6db;
  color: #b00020;
}
.chip.warning {
  background-color: #ffe082;
  color: #cc3f12;
}
.chip.success {
  background-color: #d6f2e7;
  color: #007544;
}
.chip.explicit {
  background-color: #223d6b;
  color: #fff;
  border-color: #061636;
}
.chip.disabled {
  background-color: #b9c1d0;
  color: #142e59;
}
.chip.light {
  background-color: #e3e6ec;
  color: #142e59;
}
.chip.primary {
  background-color: #ffca28;
  color: #142e59;
}
.chip.secondary {
  background-color: #bbdffe;
  color: #0849a4;
}
.chip.tertiary {
  background-color: #e3f2fe;
  color: #0849a4;
}
.chip.removable {
  background-color: #e3e6ec;
  color: #142e59;
}
.chip.removable dtm-ui-icon {
  color: #223d6b;
}
.chip.removable dtm-ui-icon:hover {
  color: #637292;
}

.dialog-header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.dialog-header [mat-dialog-title] {
  flex: 1 1 auto;
  margin-top: 3px;
}
.dialog-header button {
  flex: 0 0 auto;
}

@media screen and (max-width: 430.98px), screen and (min-width: 431px) and (max-width: 767.98px) {
  .wide-mobile-dialog {
    max-width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
  }
}

.selectable-tile {
  border: 1px solid #8d99b1;
  border-radius: 8px;
  padding: var(--selectable-tile-padding, 16px 20px);
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.selectable-tile .selectable-tile-icon {
  display: block;
}
.selectable-tile .selectable-tile-icon dtm-ui-icon {
  font-size: 1.5rem;
}
.selectable-tile .selectable-tile-wrapper .selectable-tile-label {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
  margin-bottom: 8px;
}
.selectable-tile .selectable-tile-wrapper .selectable-tile-description {
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 600;
  font-weight: 500;
  display: block;
  color: #637292;
  margin: 8px 0;
}
.selectable-tile.horizontal {
  flex-direction: row;
}
.selectable-tile.horizontal .selectable-tile-icon {
  justify-self: start;
}
.selectable-tile.horizontal .selectable-tile-wrapper {
  text-align: left;
}
.selectable-tile.selected {
  border-width: 2px;
  border-color: #1477d5;
  padding: var(--selectable-tile-selected-padding, 15px 19px);
  box-shadow: 0 6px 10px rgba(20, 119, 213, 0.14), 0 1px 18px rgba(20, 119, 213, 0.12), 0 3px 5px rgba(20, 119, 213, 0.2);
}
.selectable-tile.selected.disabled, .disabled .selectable-tile.selected {
  box-shadow: 0 6px 10px rgba(141, 153, 177, 0.14), 0 1px 18px rgba(141, 153, 177, 0.12), 0 3px 5px rgba(141, 153, 177, 0.2);
  border-width: 2px;
}
.selectable-tile:hover, .selectable-tile.hovered {
  border-color: #1477d5;
  background-color: #e3f2fe;
  box-shadow: 0 1px 18px rgba(29, 136, 238, 0.12);
}
.selectable-tile.disabled, .disabled .selectable-tile {
  opacity: 0.6;
  pointer-events: none;
  box-shadow: none;
  border: 1px solid #8d99b1;
}

quill-editor:not(.error) .ql-toolbar.ql-snow {
  border-color: #b9c1d0;
  border-radius: 8px 8px 0 0;
}
quill-editor:not(.error) .ql-container {
  border-color: #b9c1d0;
}
quill-editor:not(.error) .ql-editor:focus, quill-editor:not(.error) .ql-editor:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: -2px;
  border-radius: 0 0 8px 8px;
}

quill-editor.error .ql-toolbar.ql-snow {
  border-color: #b00020;
  border-radius: 8px 8px 0 0;
}
quill-editor.error .ql-container {
  border-color: #b00020;
}

.ql-container.ngx-quill-view-html .ql-editor {
  white-space: normal;
}

.ql-container {
  width: var(--quillContainerWidth);
  height: var(--quillContainerHeight);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.ql-container .ql-editor {
  font-size: 0.875rem;
  font-family: Manrope, Arial, sans-serif;
}
.ql-container .ql-editor p:not(:first-of-type) {
  margin: 16px 0;
}
.ql-container .ql-editor .ql-size-small {
  font-size: 0.75rem;
}
.ql-container .ql-editor .ql-size-large {
  font-size: 1.25rem;
}
.ql-container .ql-editor .ql-size-huge {
  font-size: 2rem;
}
.ql-container .ql-editor {
  width: var(--quillContainerWidth);
  height: var(--quillEditorHeight);
}

.ql-toolbar.ql-snow {
  display: block;
}
.ql-toolbar.ql-snow .ql-size {
  width: 130px;
}
.ql-toolbar.ql-snow button:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: 0;
  border-radius: 4px;
}
.ql-toolbar.ql-snow .ql-picker:not(.ql-expanded):focus-within .ql-picker-label {
  outline: 2px solid #1477d5;
  outline-offset: 0;
  border-radius: 4px;
  color: #1477d5;
}
.ql-toolbar.ql-snow .ql-picker:not(.ql-expanded):focus-within .ql-picker-label .ql-stroke {
  stroke: #1477d5;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  outline: none;
  border: none;
}
.ql-toolbar.ql-snow .ql-picker-item:focus-within {
  outline: 2px solid #1477d5;
  outline-offset: 0;
  border-radius: 4px;
  color: #1477d5;
}
.ql-toolbar.ql-snow .ql-picker-options {
  padding: 0;
}
.ql-toolbar.ql-snow .ql-picker-options .ql-picker-item:focus-within {
  outline: 2px solid #1477d5;
  outline-offset: 0;
}
.ql-toolbar.ql-snow .ql-picker-options .ql-picker-item:focus-within .ql-stroke {
  stroke: #1477d5;
}

.ql-formats {
  position: relative;
}

.ql-custom-dropdown {
  display: none;
  position: absolute;
  width: 100px;
  left: 100%;
  top: 20px;
  flex-direction: column;
  background-color: #fff;
  z-index: 2;
  border-radius: 8px;
  padding: 4px 8px;
  box-shadow: 0 8px 16px rgba(34, 61, 107, 0.12);
}
.ql-custom-dropdown button[type=button] {
  width: auto;
  text-align: left;
  padding: 4px;
  height: unset;
}
.ql-custom-dropdown button[type=button]:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: 0;
}

.ql-snow.ql-toolbar .box-button {
  width: auto;
}

.editor-joined-view {
  border: 1px solid #b9c1d0;
  border-radius: 8px;
}
.editor-joined-view .ql-container.ql-snow,
.editor-joined-view .ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid #e3e6ec;
  border-radius: 0;
}
.editor-joined-view quill-editor.error .ql-container.ql-snow {
  border: 1px solid #b00020;
}
.editor-joined-view quill-editor:not(.error) .ql-editor:focus, .editor-joined-view quill-editor:not(.error) .ql-editor:focus-visible {
  border: 1px solid #1477d5;
  border-radius: 0;
}

.dtm-table dtm-ui-loader,
.dtm-table + dtm-ui-loader {
  --loader-border-radius: 8px;
}
.dtm-table,
.dtm-table table {
  box-shadow: 0 6px 12px rgba(67, 87, 126, 0.14);
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  background: transparent;
}
.dtm-table.no-box-shadow,
.dtm-table table.no-box-shadow {
  box-shadow: none;
}
.dtm-table [mat-header-row] th:first-child {
  border-top-left-radius: 8px;
}
.dtm-table [mat-header-row] th:last-child {
  border-top-right-radius: 8px;
}
.dtm-table [aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before, .dtm-table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before, .dtm-table [aria-sort=none] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before, .dtm-table [aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before, .dtm-table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before, .dtm-table [aria-sort=none] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before {
  position: absolute;
  color: #b9c1d0;
  font-weight: bold;
}
.dtm-table [aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before, .dtm-table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before, .dtm-table [aria-sort=none] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before {
  content: "↑";
}
.dtm-table [aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before, .dtm-table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before, .dtm-table [aria-sort=none] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before {
  content: "↓";
  left: -4px;
}
.dtm-table .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(-25%) !important;
  font-size: 1rem;
  margin: 0 0 0 4px;
}
.dtm-table .mat-sort-header-arrow .mat-sort-header-stem {
  display: none;
}
.dtm-table .mat-sort-header-arrow .mat-sort-header-indicator {
  transform: translateY(0) !important;
}
.dtm-table .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left,
.dtm-table .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right,
.dtm-table .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-middle {
  height: 0;
  width: 0;
  transform: rotate(0) !important;
}
.dtm-table [aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-left::before {
  color: #1477d5;
}
.dtm-table [aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator .mat-sort-header-pointer-right::before {
  color: #1477d5;
}
.dtm-table .pagination-row td {
  border-bottom: none;
  background-color: #fff;
}
.dtm-table .pagination-row td:first-child {
  border-bottom-left-radius: 8px;
}
.dtm-table .pagination-row td:last-child {
  border-bottom-right-radius: 8px;
}
.dtm-table [mat-header-cell] {
  padding: 24px 8px 16px;
  color: #637292;
  border: none;
}
.dtm-table [mat-cell] {
  padding: 8px;
  word-break: break-word;
  color: #142e59;
  border: none;
}
.dtm-table [mat-header-cell],
.dtm-table [mat-cell] {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
  background-color: #fff;
}
.dtm-table [mat-header-cell].mat-column-actions,
.dtm-table [mat-cell].mat-column-actions {
  width: 60px;
}
.dtm-table [mat-header-cell].mat-column-expandHandle,
.dtm-table [mat-cell].mat-column-expandHandle {
  width: 44px;
  padding-left: 8px;
  color: #223d6b;
}
.dtm-table [mat-header-cell].mat-column-actions .button-icon, .dtm-table [mat-header-cell].mat-column-actions .button-icon-small, .dtm-table [mat-header-cell].mat-column-actions .button-icon-xsmall, .dtm-table [mat-header-cell].mat-column-expandHandle .button-icon, .dtm-table [mat-header-cell].mat-column-expandHandle .button-icon-small, .dtm-table [mat-header-cell].mat-column-expandHandle .button-icon-xsmall,
.dtm-table [mat-cell].mat-column-actions .button-icon,
.dtm-table [mat-cell].mat-column-actions .button-icon-small,
.dtm-table [mat-cell].mat-column-actions .button-icon-xsmall,
.dtm-table [mat-cell].mat-column-expandHandle .button-icon,
.dtm-table [mat-cell].mat-column-expandHandle .button-icon-small,
.dtm-table [mat-cell].mat-column-expandHandle .button-icon-xsmall {
  padding: 8px;
}
.dtm-table [mat-header-cell].mat-column-actions .button-icon:hover, .dtm-table [mat-header-cell].mat-column-actions .button-icon-small:hover, .dtm-table [mat-header-cell].mat-column-actions .button-icon-xsmall:hover, .dtm-table [mat-header-cell].mat-column-expandHandle .button-icon:hover, .dtm-table [mat-header-cell].mat-column-expandHandle .button-icon-small:hover, .dtm-table [mat-header-cell].mat-column-expandHandle .button-icon-xsmall:hover,
.dtm-table [mat-cell].mat-column-actions .button-icon:hover,
.dtm-table [mat-cell].mat-column-actions .button-icon-small:hover,
.dtm-table [mat-cell].mat-column-actions .button-icon-xsmall:hover,
.dtm-table [mat-cell].mat-column-expandHandle .button-icon:hover,
.dtm-table [mat-cell].mat-column-expandHandle .button-icon-small:hover,
.dtm-table [mat-cell].mat-column-expandHandle .button-icon-xsmall:hover {
  background-color: #bbdffe;
}
.dtm-table .mat-header-cell:last-of-type,
.dtm-table .mat-cell:last-of-type,
.dtm-table .mat-footer-cell:last-of-type {
  padding-right: 8px;
}
.dtm-table [mat-row].expanded + .expansion-row .expanded-row-content:not(:empty) {
  padding-top: 12px;
  padding-bottom: 12px;
}
.dtm-table .expansion-row .expanded-row-content {
  padding: 0 8px 0 60px;
  transition: padding 300ms;
}
.dtm-table .expandable-pointer {
  cursor: pointer;
}
.dtm-table [mat-row].expanded td.mat-cell {
  border-bottom-color: #bbdffe;
}
.dtm-table [mat-row]:hover td,
.dtm-table [mat-row].expanded td,
.dtm-table [mat-row].expanded + tr.expansion-row td {
  background-color: #e3f2fe;
}
.dtm-table [mat-row].expanded + tr.expansion-row td {
  border-bottom-width: 1px;
}
.dtm-table [mat-row] + tr.expansion-row td {
  border-top: 1px solid #e3e6ec;
}
.dtm-table [mat-row]:not(.expansion-row) + tr:not(.expansion-row) td {
  border-top: 1px solid #e3e6ec;
}
.dtm-table [mat-footer-row] dtm-ui-error {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.dtm-table .hide-footer {
  display: none;
}
.dtm-table tr.expansion-row {
  height: 0;
}
.dtm-table tr.expansion-row td {
  border-bottom-width: 0;
  padding: 0;
}
.dtm-table tr.pagination-row {
  border-top-width: 1px;
}
.dtm-table .sub-cell {
  display: inline-block;
}
.dtm-table .sub-cell:not(:last-child) {
  margin-right: 100px;
  margin-bottom: 16px;
}
.dtm-table .sub-cell.full-width {
  display: block;
  margin-right: 0;
}
.dtm-table dtm-ui-popover {
  vertical-align: text-bottom;
}
.dtm-table .no-results-row.mat-footer-row td.mat-footer-cell {
  padding: 0;
  border-bottom: none;
}
.dtm-table dtm-ui-no-results {
  border-radius: 0 0 8px 8px;
  box-shadow: none;
}
.dtm-table .expand-handle {
  display: inline-block;
  transition: transform 0.3s;
}
.dtm-table .expand-handle.expanded {
  transform: rotate(90deg);
}

.mat-menu-panel[role=menu] {
  box-shadow: -1px 0 10px rgba(6, 22, 54, 0.08), 0 1px 18px rgba(6, 22, 54, 0.12), 0px 3px 5px rgba(6, 22, 54, 0.2);
  background: #fff;
  border-radius: 6px;
  min-height: auto;
}
.mat-menu-panel[role=menu] .mat-menu-content:not(:empty) {
  padding: 8px 0;
}
.mat-menu-panel[role=menu] .mat-menu-item {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
  height: auto;
  padding: 10px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #142e59;
}
.mat-menu-panel[role=menu] .mat-menu-item:disabled {
  opacity: 0.5;
}
.mat-menu-panel[role=menu] .mat-menu-item:hover:not(:disabled) {
  background-color: #e3f2fe;
}
.mat-menu-panel[role=menu] .mat-menu-item:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: -2px;
  border-radius: 4px;
}
.mat-menu-panel[role=menu] .mat-menu-item dtm-ui-icon {
  color: var(--mat-menu-icon-color, #223d6b);
}

.mat-menu-panel.wide-panel {
  width: auto;
  max-width: inherit;
}

.dtm-tabs.mat-tab-header,
.dtm-tabs .mat-tab-header {
  box-shadow: inset 0px -1px 0px 0px #e3e6ec;
}
.dtm-tabs.mat-tab-header .mat-tab-label,
.dtm-tabs.mat-tab-header .mat-tab-link,
.dtm-tabs .mat-tab-header .mat-tab-label,
.dtm-tabs .mat-tab-header .mat-tab-link {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
  line-height: 20px;
  color: #637292;
  opacity: 1;
  height: auto;
  padding: 6px 16px 4px;
  border-bottom: 2px solid transparent;
  min-width: 0;
}
.dtm-tabs.mat-tab-header .mat-tab-label:focus-visible,
.dtm-tabs .mat-tab-header .mat-tab-label:focus-visible {
  outline: 2px solid #1477d5;
  outline-offset: -2px;
  border-radius: 4px;
}
.dtm-tabs.mat-tab-header .mat-tab-label:not(.mat-tab-label-active):not(.mat-tab-disabled):hover,
.dtm-tabs .mat-tab-header .mat-tab-label:not(.mat-tab-label-active):not(.mat-tab-disabled):hover {
  color: #223d6b;
}
.dtm-tabs.mat-tab-header .mat-tab-label-active,
.dtm-tabs .mat-tab-header .mat-tab-label-active {
  color: #1477d5;
  border-bottom-color: #1477d5;
}
.dtm-tabs.mat-tab-header .mat-tab-disabled,
.dtm-tabs .mat-tab-header .mat-tab-disabled {
  color: #637292;
  opacity: 0.5;
}

.mat-tab-body-content {
  padding: var(--mat-tab-body-content-padding);
}

.mat-tab-label {
  flex-grow: var(--mat-tab-label-flex-grow);
}

.mat-tab-body {
  opacity: 0;
  transition-duration: 300ms;
}
.mat-tab-body.mat-tab-body-active {
  opacity: 1;
}

ngx-slider.dtm-slider .ngx-slider-bar {
  height: 4px;
  width: calc(100% - 14px);
  margin: 0 8px;
  background: #b9c1d0;
}
ngx-slider.dtm-slider .ngx-slider-selection,
ngx-slider.dtm-slider .ngx-slider-pointer,
ngx-slider.dtm-slider .ngx-slider-tick.ngx-slider-selected {
  background: #223d6b;
}
ngx-slider.dtm-slider .ngx-slider-pointer {
  width: 16px;
  height: 16px;
  top: auto;
  bottom: -5px;
}
ngx-slider.dtm-slider .ngx-slider-pointer::after {
  display: none;
}
ngx-slider.dtm-slider .ngx-slider-tick {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
  margin-left: 8px;
  width: 0;
  height: 0;
}
ngx-slider.dtm-slider .ngx-slider-tick .ngx-slider-tick-value {
  bottom: 8px;
  top: auto;
}

ngx-slider.dtm-slider-vertical-height.vertical .ngx-slider-bar {
  width: 8px;
  border-radius: 8px 8px 0 0;
}
ngx-slider.dtm-slider-vertical-height.vertical .ngx-slider-full-bar::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 10px;
  content: "";
  width: 20px;
  height: 2px;
  background-color: #1477d5;
}
ngx-slider.dtm-slider-vertical-height.vertical .ngx-slider-bar-wrapper {
  margin: 0 0 0 -21px;
}
ngx-slider.dtm-slider-vertical-height.vertical .ngx-slider-ticks-values-under .ngx-slider-tick-value {
  left: 32px;
  right: initial;
  top: -4px;
  font-weight: 500;
}
ngx-slider.dtm-slider-vertical-height.vertical .ngx-slider-pointer {
  width: 26px;
  height: 26px;
  top: auto;
}
ngx-slider.dtm-slider-vertical-height.vertical .ngx-slider-pointer::after {
  background-color: #fff !important;
  width: 22px;
  height: 22px;
  left: 2px;
  top: 2px;
  border-radius: 20px;
}
ngx-slider.dtm-slider-vertical-height .ngx-slider-bubble {
  display: none;
}
ngx-slider.dtm-slider-vertical-height .ngx-slider-tick {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
  margin-left: 8px;
  width: 0;
  height: 0;
}
ngx-slider.dtm-slider-vertical-height .ngx-slider-selection,
ngx-slider.dtm-slider-vertical-height .ngx-slider-pointer,
ngx-slider.dtm-slider-vertical-height .ngx-slider-tick.ngx-slider-selected {
  background-color: #1477d5;
}

mat-chip-list .mat-chip-list-wrapper {
  margin: 0;
}

mat-slide-toggle.mat-mdc-slide-toggle {
  --dtm-slide-toggle-unselected-track-color: #b9c1d0;
  --dtm-slide-toggle-unselected-handle-color: #fff;
  --dtm-slide-toggle-selected-track-color: #1477d5;
  --dtm-slide-toggle-selected-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
  --dtm-slide-toggle-width: 44px;
  --dtm-slide-toggle-height: 22px;
  --dtm-slide-toggle-handle-padding: 2px;
  height: var(--dtm-slide-toggle-height);
  --mat-slide-toggle-label-text-weight: 500;
  --mat-slide-toggle-label-text-size: inherit;
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-form-field {
  display: flex;
  align-items: flex-start;
  font-size: inherit;
  gap: var(--dtm-slide-toggle-label-button-gap, 0);
  color: inherit;
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-form-field .mdc-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch {
  --mdc-switch-handle-height: calc(var(--dtm-slide-toggle-height) - 2 * var(--dtm-slide-toggle-handle-padding));
  --mdc-switch-handle-width: calc(var(--dtm-slide-toggle-height) - 2 * var(--dtm-slide-toggle-handle-padding));
  --mdc-switch-handle-shape: calc(var(--dtm-slide-toggle-height) / 2);
  --mdc-switch-track-width: var(--dtm-slide-toggle-width);
  --mdc-switch-track-height: var(--dtm-slide-toggle-height);
  --mdc-switch-track-shape: calc(var(--dtm-slide-toggle-height) / 2);
  --mdc-switch-disabled-track-opacity: 0.5;
  margin: var(--dtm-slide-toggle-button-margin, 0 4px);
  border-radius: var(--mdc-switch-track-shape);
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch:focus-within {
  outline: 2px solid #1477d5;
  outline-offset: 2px;
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch--disabled + .mdc-label {
  opacity: 0.5;
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle-track {
  --temporary-var: calc(100% - var(--dtm-slide-toggle-handle-padding));
  transform: translateX(var(--temporary-var));
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch__track {
  --mdc-switch-selected-focus-track-color: var(--dtm-slide-toggle-selected-track-color);
  --mdc-switch-selected-hover-track-color: var(--dtm-slide-toggle-selected-track-color);
  --mdc-switch-selected-pressed-track-color: var(--dtm-slide-toggle-selected-track-color);
  --mdc-switch-selected-track-color: var(--dtm-slide-toggle-selected-track-color);
  --mdc-switch-disabled-selected-track-color: var(--dtm-slide-toggle-selected-track-color);
  --mdc-switch-unselected-focus-track-color: var(--dtm-slide-toggle-unselected-track-color);
  --mdc-switch-unselected-hover-track-color: var(--dtm-slide-toggle-unselected-track-color);
  --mdc-switch-unselected-pressed-track-color: var(--dtm-slide-toggle-unselected-track-color);
  --mdc-switch-unselected-track-color: var(--dtm-slide-toggle-unselected-track-color);
  --mdc-switch-disabled-unselected-track-color: var(--dtm-slide-toggle-unselected-track-color);
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch__handle {
  --mdc-switch-selected-handle-color: var(--dtm-slide-toggle-selected-handle-color);
  --mdc-switch-selected-focus-handle-color: var(--dtm-slide-toggle-selected-handle-color);
  --mdc-switch-selected-hover-handle-color: var(--dtm-slide-toggle-selected-handle-color);
  --mdc-switch-selected-pressed-handle-color: var(--dtm-slide-toggle-selected-handle-color);
  --mdc-switch-disabled-selected-handle-color: var(--dtm-slide-toggle-selected-handle-color);
  --mdc-switch-unselected-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
  --mdc-switch-unselected-focus-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
  --mdc-switch-unselected-hover-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
  --mdc-switch-unselected-pressed-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
  --mdc-switch-disabled-unselected-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch__handle-track {
  transform: translateX(var(--dtm-slide-toggle-handle-padding));
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch .mdc-switch__shadow,
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple,
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch .mdc-switch__icons {
  opacity: 0;
}
mat-slide-toggle.mat-mdc-slide-toggle.spread .mdc-form-field {
  justify-content: space-between;
}
mat-slide-toggle.mat-mdc-slide-toggle.spread .mdc-form-field .mdc-label {
  margin-inline: 0;
}
mat-slide-toggle.mat-mdc-slide-toggle.allow-wrap {
  height: auto;
}
mat-slide-toggle.mat-mdc-slide-toggle.allow-wrap .mdc-form-field .mdc-label {
  white-space: normal;
}
mat-slide-toggle.mat-mdc-slide-toggle.toggle-bar-center .mdc-form-field {
  align-items: center;
}

mat-spinner circle {
  stroke: #1477d5;
}

.mat-autocomplete-panel {
  background-color: #fff;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: 0 6px 10px rgba(28, 54, 100, 0.14), 0 1px 18px rgba(28, 54, 100, 0.12), 0 3px 5px rgba(28, 54, 100, 0.2);
}
.mat-autocomplete-panel mat-option.mat-option {
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
  color: #142e59;
  padding: 8px 16px;
  height: auto;
}
.mat-autocomplete-panel mat-option.mat-option.mat-option-disabled {
  opacity: 0.5;
}
.mat-autocomplete-panel mat-option.mat-option:not(.mat-option-disabled):hover {
  background-color: #e3f2fe;
}
.mat-autocomplete-panel mat-option.mat-option.mat-focus-indicator.mat-active {
  background-color: #e3f2fe;
}
.mat-autocomplete-panel.options-list {
  margin-top: -18px;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #fff;
  --mdc-plain-tooltip-supporting-text-color: #142e59;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  box-shadow: -1px 0 10px rgba(6, 22, 54, 0.08), 0 1px 18px rgba(6, 22, 54, 0.12), 0px 3px 5px rgba(6, 22, 54, 0.2);
  border-radius: 4px;
}
.mat-mdc-tooltip .mdc-tooltip__surface {
  padding: 12px;
  line-height: 150%;
}

@media screen and (min-width: 768px) and (max-width: 1024.98px), screen and (min-width: 1025px) and (max-width: 1439.98px), screen and (min-width: 1440px) {
  .clickable-image {
    cursor: pointer;
  }
  .clickable-image:focus-visible {
    outline: 2px solid #1477d5;
    outline-offset: var(--clickable-image-outline, 0px);
    border-radius: 4px;
  }
}

.card-box:not([class*=mat-elevation-z]),
mat-card.mat-card:not([class*=mat-elevation-z]) {
  border-radius: 8px;
  box-shadow: 3px 6px 16px rgba(34, 61, 107, 0.09);
  color: #061636;
  background-color: #fff;
}
.card-box.mat-elevation-z1,
mat-card.mat-card.mat-elevation-z1 {
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #b9c1d0;
  padding: 20px;
}

.mat-input-element::placeholder,
.mat-date-range-input-inner::placeholder {
  color: #637292;
}

input.mat-input-element,
textarea.mat-input-element {
  caret-color: #061636;
  margin-top: 0;
}

mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 8px 16px rgba(34, 61, 107, 0.12);
  border-radius: 8px;
}
mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]):not(.mat-expanded), mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]):not(.mat-expansion-panel-spacing), mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]):not(.mat-expansion-panel-seperated) {
  border-radius: 0;
}
mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]):first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]):last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) .mat-expansion-panel-header-title {
  color: #061636;
  font-size: 1rem;
  font-weight: 700;
}
mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]).mat-expansion-panel-seperated {
  border-radius: 8px;
  margin-bottom: 24px;
}

.mat-expansion-indicator::after {
  color: #1d88ee;
}

mat-dialog-container.mat-dialog-container {
  padding: 16px 24px;
  border-radius: 12px;
  box-shadow: none;
  color: #142e59;
  background-color: #fff;
}
mat-dialog-container.mat-dialog-container .mat-dialog-title {
  font-size: 1.25rem;
  line-height: 160%;
  font-weight: 700;
  color: #061636;
  margin-bottom: 8px;
}
@media screen and (max-width: 430.98px), screen and (min-width: 431px) and (max-width: 767.98px) {
  mat-dialog-container.mat-dialog-container .mat-dialog-title {
    font-size: 1rem;
    line-height: 140%;
  }
}
mat-dialog-container.mat-dialog-container .mat-dialog-actions {
  margin: 16px 0 0;
  min-height: auto;
  padding: 0;
  gap: 16px;
}

.mat-menu-panel.notifications-menu .mat-menu-content:not(:empty) {
  height: 100%;
}

mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]).no-box-shadow,
.mat-expansion-panel:not([class*=mat-elevation-z]).no-box-shadow,
mat-card.mat-card:not([class*=mat-elevation-z]).no-box-shadow {
  box-shadow: none;
}

.conversation-sheet {
  padding: 0;
  width: 532px;
  min-width: 532px;
  max-width: 532px;
  font-weight: 500;
}
.conversation-sheet .mat-dialog-content {
  max-height: 70vh;
}
@media screen and (max-width: 430.98px), screen and (min-width: 431px) and (max-width: 767.98px) {
  .conversation-sheet {
    max-width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
  }
}

.mat-menu-panel.notifications-menu {
  max-width: none;
}

.link {
  color: #1477d5;
}
.link:hover {
  text-decoration: underline;
}

.cdk-overlay-dark-backdrop {
  background: rgba(6, 22, 54, 0.6);
}

.capitalize {
  text-transform: capitalize;
}